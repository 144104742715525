import React, { useState, useEffect, useCallback } from 'react';
import Header from '../../components/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SuccessNotification from '../../components/Notifications/SuccessNotification';
import ErrorNotification from '../../components/Notifications/ErrorNotification';
import LoginNotification from "../../components/Notifications/LoginNotification";
import DeleteModal from '../../components/DeleteModal';
import { faEdit, faTrashAlt, faUser,  faUserPlus,  faSearch, faCircleCheck,faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import Table from '../../components/Table';
import Section from '../../components/Section';
import apiClient from "../../axios";
import { Tooltip } from "react-tooltip";
import { useNavigate } from 'react-router-dom';

const DistributorsList = ({ handleLogout }) => {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const itemsPerPage = 10;
  const [isSuccessVisible, setIsSuccessVisible] = useState(false);
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [isInfoVisible, setIsInfoVisible] = useState(false);
  const [deleteItem, setDeleteItem] = useState(false);
  const [selectedDeleteItem, setSelectedDeleteItem] = useState(null);
  const navigate = useNavigate();

  const hasPermission = (permission) => {
    const permissions = JSON.parse(localStorage.getItem('permissions')) || [];
    return permissions.includes(permission);
  };

  const fetchAllData = async (page = 1) => {
    try {
      setLoading(true);
      const response = await apiClient.get(`/distributors?page=${page}`);
      if (Array.isArray(response.data.results)) {
        setData(response.data.results);
        setTotalItems(response.data.total);
      } else {
        setData([response.data.results]);
        setTotalItems(1);
      }
    } catch (error) {
      setIsErrorVisible(true);
      if (error.response && error.response.status === 401) {
        setIsInfoVisible(true);
      }
    } finally {
      setIsSuccessVisible(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllData(currentPage);
  }, [currentPage]);

  const handleRefresh = () => {
    fetchAllData(currentPage);
  };

  const handleSearch = useCallback(async (page = 1) => {
    try {
      // Construir la URL con los query params
      const params = new URLSearchParams();
      if (search) params.append('search', search);

      const response = await apiClient.get(`/distributors?${params.toString()}&page=${page}`);

      if (Array.isArray(response.data.results)) {
        setData(response.data.results); // Si es un array, lo dejamos tal cual
      } else {
        setData([response.data.results]); // Si es un objeto, lo encapsulamos en un array
      }
      setTotalItems(response.data.total);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setIsInfoVisible(true);
      }
    }
  }, [search]);
  const handleFresh = async (code) => {
    handleSearch(1)
  };

  /*const handleDelete = async (code) => {
    try {
      const url = `/distributors/${code}`;
      await apiClient.delete(url);
      handleRefresh();
    } catch (error) {
      setIsErrorVisible(true);
      if (error.response && error.response.status === 401) {
        setIsInfoVisible(true);
      }
    }
  };*/

  const handleDeleteModal = (item) => {
    setSelectedDeleteItem(item);
    setDeleteItem(true);
  }
  const handleCloseDeleteModal = () => {
    setDeleteItem(false);
    setSelectedDeleteItem(null);
    handleRefresh()
  };

  const columns = [
    { title: "Código", key: "code" },
    { title: "Nombre", key: "name" },
    { title: "Cliente", key: "client" },
    { title: "Estado", key: "name" },
    { title: "Acciones", key: "acciones" },
  ];

  const renderRow = (item, index) => (
    <>
      <td>{item.code}</td>
      <td>{item.name}</td>
      <td>{item.cif}</td>
      <td>
        {!item.purchaseBlock ? (
          <button
            className="status_button"
          >
            <FontAwesomeIcon
              icon={faCircleCheck}
              style={{ color: "green", fontSize: "24px" }}
            />
          </button>
        ) : (
          <button
            className="status_button"
          >
            <FontAwesomeIcon
              className="status_icon"
              icon={faCircleXmark}
              style={{ color: "red", fontSize: "24px" }}
            />
          </button>
        )}
      </td>
      <td>
        <div className="button-container">
          <Tooltip id="edit-tooltip" className="custom-tooltip" />
          {hasPermission('DISTRIBUTOR_UPDATE') && (
            <button
              data-tooltip-id="edit-tooltip"
              className="icon-button edit-button"
              data-tooltip-content="Editar"
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/DistributorsForm/${item.code}`);
              }}
            >
              <FontAwesomeIcon icon={faEdit} />
            </button>
          )}
          <Tooltip id="delete-tooltip" className="custom-tooltip" />
          {hasPermission('DISTRIBUTOR_DELETE') && (
            <button
              data-tooltip-id="delete-tooltip"
              data-tooltip-content="Eliminar"
              className="icon-button delete-button"
              onClick={() => handleDeleteModal(item)}
              //onClick={() => handleDelete(item.code)}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </button>
          )}
        </div>
      </td>
    </>
  );

  return (
    <div className="home-container">
      <Header onLogout={handleLogout} title='Distribuidores' />
      <div className="home-content">
        <Section>
          <div className="filter-form">
            <div className="basic-info-form-group">
              <input
                type="text"
                id="customer"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Distribuidor"
              />
            </div>
            <button className="search-button-customer" onClick={handleFresh}>
              <FontAwesomeIcon icon={faSearch} className="search-icon" />
              Buscar
            </button>
          </div>
          <div className="button-add">
            {hasPermission('DISTRIBUTOR_CREATE') && (
              <button
                className="basic-custom-button"
                onClick={(e) => {
                  e.stopPropagation();
                  navigate('/DistributorsForm');
                }}
              >
                <FontAwesomeIcon className="basic-shortcut-icon" icon={faUserPlus} />
                Crear Nuevo Distribuidor
              </button>
            )}
          </div>
        </Section>

        <Table
          title="Lista de Distribuidores"
          rows={data}
          columns={columns}
          icon={faUser}
          renderRow={renderRow}
          currentPage={currentPage}
          totalItems={totalItems}
          itemsPerPage={itemsPerPage}
          onPageChange={setCurrentPage}
          onRefresh={handleRefresh}
          loading={loading}
          flagPagination={true}
        />
        <SuccessNotification
          message={"Se ha cargado correctamente"}
          isVisible={isSuccessVisible}
          onClose={() => setIsSuccessVisible(false)}
        />
        <ErrorNotification
          message="Ups! Ocurrió un Problema"
          isVisible={isErrorVisible}
          onClose={() => setIsErrorVisible(false)}
        />
        <LoginNotification
          message="Vuelve a iniciar sesión"
          isVisible={isInfoVisible}
          onClose={() => setIsInfoVisible(false)}
          onLogout={handleLogout}
        />
        {selectedDeleteItem && (
          <DeleteModal
            isVisible={deleteItem}
            onClose={handleCloseDeleteModal}
            identificator={selectedDeleteItem.code}
            url={`/distributors`}
          />
        )}
      </div>
    </div>
  );
};

export default DistributorsList;