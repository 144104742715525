import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from '../../components/Header';
import "./Management.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers, faClipboardCheck, faIdCard, faUserTie, faListCheck, faList, faCircleUser, faUserGear, faBuilding, faServer, faChalkboardUser } from "@fortawesome/free-solid-svg-icons";
import LoginNotification from "../../components/Notifications/LoginNotification";

const Management = ({ handleLogout }) => {
    const [isInfoVisible, setIsInfoVisible] = useState(false);
    const navigate = useNavigate();
    const role = localStorage.getItem('role');
    const hasPermission = (permission) => {
        const permissions = JSON.parse(localStorage.getItem('permissions')) || [];
        return permissions.includes(permission);
    };

    const goToPersonal = () => navigate("/StaffList");
    const goToPermisos = () => navigate("/PermissionsList");
    const goToRol = () => navigate("/RolList");
    const goToDistributors = () => navigate("/DistributorsList");
    const goToCredentials = () => navigate("/CredentialsList");
    const goToParameters = () => navigate("/ParametersList");
    const goToUsers = () => navigate("/UsersList");
    const goToCustomersManagement = () => navigate("/CustomerManagement");
    const goToCompaniesManagement = () => navigate("/CompaniesManagement");
    const goToTechnician = () => navigate("/TechnicianList");
    const goToServer = () => navigate("/ServerList");

    return (
        <div className="home-container">
            <Header title="Administración" onLogout={handleLogout} />
            <div className="home_modules-container">
                {hasPermission('DISTRIBUTOR_LIST') && (
                    <div
                        className="module_item-container2"
                        style={{ backgroundColor: "#0C3468" }}
                        onClick={goToDistributors}
                    >
                        <FontAwesomeIcon icon={faUserTie} size="2x" />
                        <p>Distribuidores</p>
                    </div>
                )}
                {hasPermission('MANAGEMENT_USER_LIST') && (
                    <div
                        className="module_item-container2"
                        style={{ backgroundColor: "#0C3468" }}
                        onClick={goToPersonal}
                    >
                        <FontAwesomeIcon icon={faUsers} size="2x" />
                        <p>Personal</p>
                    </div>
                )}
                {role === 'SUPER_ADMIN' && (
                    <div
                        className="module_item-container2"
                        style={{ backgroundColor: "#0C3468" }}
                        onClick={goToTechnician}
                    >
                        <FontAwesomeIcon icon={faChalkboardUser} size="2x" />
                        <p>Equipo Técnico</p>
                    </div>
                )}
                {hasPermission('PERMISSION_LIST') && (
                    <div
                        className="module_item-container2"
                        style={{ backgroundColor: "#0C3468" }}
                        onClick={goToPermisos}
                    >
                        <FontAwesomeIcon icon={faClipboardCheck} size="2x" />
                        <p>Permisos</p>
                    </div>
                )}
                {hasPermission('ROLE_LIST') && (
                    <div
                        className="module_item-container2"
                        style={{ backgroundColor: "#0C3468" }}
                        onClick={goToRol}
                    >
                        <FontAwesomeIcon icon={faListCheck} size="2x" />
                        <p>Roles</p>
                    </div>
                )}
                <div className="line-break"></div>
                {hasPermission('CREDENTIAL_LIST') && (
                    <div
                        className="module_item-container2"
                        style={{ backgroundColor: "#104588" }}
                        onClick={goToCredentials}
                    >
                        <FontAwesomeIcon icon={faIdCard} size="2x" />
                        <p>Credenciales</p>
                    </div>
                )}
                {hasPermission('PARAMETER_LIST') && (
                    <div
                        className="module_item-container2"
                        style={{ backgroundColor: "#104588" }}
                        onClick={goToParameters}
                    >
                        <FontAwesomeIcon icon={faList} size="2x" />
                        <p>Parámetros</p>
                    </div>
                )}
                {hasPermission('CUSTOMER_MANAGEMENT') && (
                    <div
                        className="module_item-container2"
                        style={{ backgroundColor: "#104588" }}
                        onClick={goToCustomersManagement}
                    >
                        <FontAwesomeIcon icon={faUserGear} size="2x" />
                        <p>Gestión de Clientes</p>
                    </div>
                )}
                <div className="line-break"></div>
                {(role === 'SUPER_ADMIN' || role === 'ADMIN')  && (
                    <div
                        className="module_item-container2"
                        style={{ backgroundColor: "#12509B" }}
                        onClick={goToServer}
                    >
                        <FontAwesomeIcon icon={faServer} size="2x" />
                        <p>Servidores</p>
                    </div>
                )}
                {hasPermission('COMPANY_ADMIN_LIST') && (
                    <div
                        className="module_item-container2"
                        style={{ backgroundColor: "#12509B" }}
                        onClick={goToCompaniesManagement}
                    >
                        <FontAwesomeIcon icon={faBuilding} size="2x" />
                        <p>Gestión de Compañías</p>
                    </div>
                )}

                {hasPermission('USER_LIST') && (
                    <div
                        className="module_item-container2"
                        style={{ backgroundColor: "#12509B" }}
                        onClick={goToUsers}
                    >
                        <FontAwesomeIcon icon={faCircleUser} size="2x" />
                        <p>Usuarios Fenix</p>
                    </div>
                )}

                <LoginNotification
                    message="Vuele a iniciar sesión"
                    isVisible={isInfoVisible}
                    onClose={() => setIsInfoVisible(false)}
                    onLogout={handleLogout}
                />
            </div>
        </div >
    );
};

export default Management;
