import React, { useState } from "react";
import SuccessNotification from "../../components/Notifications/SuccessNotification";
import ErrorNotification from "../../components/Notifications/ErrorNotification";
import LoginNotification from "../../components/Notifications/LoginNotification";
import Loader from "../../components/Loader";
import apiClient from "../../axios";
import './DeleteModal.css';

const DeleteModal = ({ isVisible, onClose, identificator, url }) => {
    const [loading, setLoading] = useState(false);
    const [isSuccessVisible, setIsSuccessVisible] = useState(false);
    const [isErrorVisible, setIsErrorVisible] = useState(false);
    const [errorsMessage, setErrorMessage] = useState({});
    const [isLoginVisible, setIsLoginVisible] = useState(false);

    const handleDelete = async () => {
        try {
            setLoading(true);
            const deleteUrl = `${url}/${identificator}`;
            await apiClient.delete(deleteUrl);
            setIsSuccessVisible(true);
            setTimeout(() => {
                onClose();
            }, 1000);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                localStorage.clear();
                setErrorMessage("El elemento no se ha podido eliminar.");
            }
            setIsErrorVisible(true);
        } finally {
            setLoading(false);
        }
    };

    return (
        isVisible && (
            <div className="info-notification-info-overlay">
                <div className="info-notification-info2" style={{ width: "400px" }}>
                    <div className="message-container-info">
                        <h3 className="basic-info-form-title">Eliminar</h3>
                    </div>
                    <div className="message-container-info">
                        <div className="basic-info-form-group-add">
                            <label>¿Está seguro de eliminar este elemento?</label>
                        </div>
                    </div>
                    <div className="button-container-info">
                        <button className="cancel-btn-info" onClick={onClose}>
                            Cancelar
                        </button>
                        <button className="ok-btn-info" onClick={handleDelete}>
                            OK
                        </button>
                    </div>
                    <SuccessNotification
                        message={"El elemento se ha ELIMINADO correctamente."}
                        isVisible={isSuccessVisible}
                        onClose={() => setIsSuccessVisible(false)}
                    />
                    <ErrorNotification
                        message={errorsMessage}
                        isVisible={isErrorVisible}
                        onClose={() => setIsErrorVisible(false)}
                    />
                    <LoginNotification
                        message="Vuelve a iniciar sesión"
                        isVisible={isLoginVisible}
                        onClose={() => setIsLoginVisible(false)}
                    />
                    {loading && <Loader />}
                </div>
            </div>
        )
    );
};

export default DeleteModal;
