import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { LayoutSidebar } from './components'; // Importa el componente Layout
import { Login, Contract, Module, HomePage, ModuleList, ClientForm, ModulesForm, Customers, ModuleNew, ContractNew, ModuleContract, ContratAll, ContractEdition, PurchaseForm, PurchaseSummary, PurchaseList, PurchaseView, StaffList, StaffForm, PermissionsList, PermissionFrom, Management, RolList, RolForm, DistributorsList, DistributorsForm, CredentialsList, CredentialsForm, ParametersList, ParametersForm, UsersList, UsersForm, CompaniesList, CompaniesForm, CustomerManagement, ContractReassignment, DistributorReassignment, ResetPassword, CompaniesManagement, TechnicianList, TechnicianForm, ServerList, ServerForm, ConnecctionKpiForm, ConnectionKpiList} from "./Pages";

function App() {

  const role = localStorage.getItem('role');
  const hasPermission = (permission) => {
    const permissions = JSON.parse(localStorage.getItem('permissions')) || [];
    return permissions.includes(permission);
  };
  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('token'));

  const handleLogin = (token) => {
    localStorage.setItem('token', token);
    setIsAuthenticated(true);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
  };

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            !isAuthenticated ? (
              <Login onLogin={handleLogin} />
            ) : (
              <Navigate to="/HomePage" />
            )
          }
        />
        <Route
          path="/Home"
          element={
            isAuthenticated ? (
              <LayoutSidebar><HomePage handleLogout={handleLogout} /></LayoutSidebar>
            ) : (
              <Navigate to="/" />
            )
          }
        />
        {hasPermission('CONTRACT_LIST') && (
          <Route
            path="/Contract"
            element={
              isAuthenticated ? (
                <LayoutSidebar><Contract handleLogout={handleLogout} /></LayoutSidebar>
              ) : (
                <Navigate to="/" />
              )
            }
          />
        )}
        <Route
          path="/ContractAll"
          element={
            isAuthenticated ? (
              <LayoutSidebar><ContratAll handleLogout={handleLogout} /></LayoutSidebar>
            ) : (
              <Navigate to="/" />
            )
          }
        />
        {hasPermission('CONTRACT_CREATE') && (
          <Route
            path="/ContractNew"
            element={
              isAuthenticated ? (
                <LayoutSidebar><ContractNew handleLogout={handleLogout} /></LayoutSidebar>
              ) : (
                <Navigate to="/" />
              )
            }
          />
        )}
        {hasPermission('CONTRACT_UPDATE') && (
          <Route
            path="/ContractNew/:productId"
            element={
              isAuthenticated ? (
                <LayoutSidebar><ContractNew handleLogout={handleLogout} /></LayoutSidebar>
              ) : (
                <Navigate to="/" />
              )
            }
          />
        )}
        {hasPermission('MODULE_UPDATE', 'MODULE_CREATE') && (
          <Route
            path="/ModuleContract/*"
            element={
              isAuthenticated ? (
                <LayoutSidebar>
                  <ModuleContract handleLogout={handleLogout} />
                </LayoutSidebar>
              ) : (
                <Navigate to="/" />
              )
            }
          />
        )}
        {hasPermission('CUSTOMER_CREATE') && (
          <Route
            path="/ClientForm"
            element={
              isAuthenticated ? (
                <LayoutSidebar><ClientForm handleLogout={handleLogout} /></LayoutSidebar>
              ) : (
                <Navigate to="/" />
              )
            }
          />
        )}
        {hasPermission('CUSTOMER_UPDATE') && (
          <Route path="/ClientForm/:customerCode"
            element={
              isAuthenticated ? (
                <LayoutSidebar><ClientForm handleLogout={handleLogout} /></LayoutSidebar>
              ) : (
                <Navigate to="/" />
              )
            }
          />
        )}
        {hasPermission('PRODUCT_LIST') && (
          <Route
            path="/Module"
            element={
              isAuthenticated ? (
                <LayoutSidebar><Module handleLogout={handleLogout} /></LayoutSidebar>
              ) : (
                <Navigate to="/" />
              )
            }
          />
        )}
        {hasPermission('CUSTOMER_LIST') && (
          <Route
            path="/Customers"
            element={
              isAuthenticated ? (
                <LayoutSidebar><Customers handleLogout={handleLogout} /></LayoutSidebar>
              ) : (
                <Navigate to="/" />
              )
            }
          />
        )}
        {hasPermission('MODULE_LIST') && (
          <Route
            path="/ModulesForm"
            element={
              isAuthenticated ? (
                <LayoutSidebar><ModulesForm handleLogout={handleLogout} /></LayoutSidebar>
              ) : (
                <Navigate to="/" />
              )
            }
          />
        )}
        <Route
          path="/ModuleList"
          element={
            isAuthenticated ? (
              <LayoutSidebar><ModuleList handleLogout={handleLogout} /></LayoutSidebar>
            ) : (
              <Navigate to="/" />
            )
          }
        />
        {hasPermission('PRODUCT_CREATE') && (
          <Route
            path="/ModuleNew"
            element={
              isAuthenticated ? (
                <LayoutSidebar><ModuleNew handleLogout={handleLogout} /></LayoutSidebar>
              ) : (
                <Navigate to="/" />
              )
            }
          />
        )}
        {hasPermission('PRODUCT_UPDATE', 'PRODUCT_CREATE') && (
          <Route path="/ModuleNew/:productId"
            element={
              isAuthenticated ? (
                <LayoutSidebar><ModuleNew handleLogout={handleLogout} /></LayoutSidebar>
              ) : (
                <Navigate to="/" />
              )
            }
          />
        )}
        {hasPermission('CONTRACT_VIEW') && (
          <Route path="/ContractEdition"
            element={
              isAuthenticated ? (
                <LayoutSidebar><ContractEdition handleLogout={handleLogout} /></LayoutSidebar>
              ) : (
                <Navigate to="/" />
              )
            }
          />
        )}
        {role === 'CHANEL_ADMIN' && (
          <Route
            path="/PurchaseForm"
            element={
              isAuthenticated ? (
                <LayoutSidebar><PurchaseForm handleLogout={handleLogout} /></LayoutSidebar>
              ) : (
                <Navigate to="/" />
              )
            }
          />
        )}
        {hasPermission('ORDER_CREATE') && (
          <Route
            path="/PurchaseSummary/:orderNumber"
            element={
              isAuthenticated ? (
                <LayoutSidebar><PurchaseSummary handleLogout={handleLogout} /></LayoutSidebar>
              ) : (
                <Navigate to="/" />
              )
            }
          />
        )}
        {(role === 'CHANEL_ADMIN' || role === 'SUPER_ADMIN' || role === 'ADMIN') && (
          <Route
            path="/PurchaseList"
            element={
              isAuthenticated ? (
                <LayoutSidebar><PurchaseList handleLogout={handleLogout} /></LayoutSidebar>
              ) : (
                <Navigate to="/" />
              )
            }
          />
        )}
        {(role === 'CHANEL_ADMIN' || role === 'SUPER_ADMIN' || role === 'ADMIN') && (
          <Route
            path="/PurchaseView/:orderNumber"
            element={
              isAuthenticated ? (
                <LayoutSidebar><PurchaseView handleLogout={handleLogout} /></LayoutSidebar>
              ) : (
                <Navigate to="/" />
              )
            }
          />
        )}
        {(hasPermission('MANAGEMENT_USER_LIST') || hasPermission('PERMISSION_LIST') || hasPermission('ROLE_LIST')
          || hasPermission('DISTRIBUTOR_LIST') || hasPermission('CREDENTIAL_LIST') || hasPermission('PARAMETER_LIST')
          || hasPermission('USER_LIST') || hasPermission('CUSTOMER_MANAGEMENT')
        )
          && (
            <Route
              path="/Management"
              element={
                isAuthenticated ? (
                  <LayoutSidebar><Management handleLogout={handleLogout} /></LayoutSidebar>
                ) : (
                  <Navigate to="/" />
                )
              }
            />
          )}
        {hasPermission('MANAGEMENT_USER_LIST') && (
          <Route
            path="/StaffList"
            element={
              isAuthenticated ? (
                <LayoutSidebar><StaffList handleLogout={handleLogout} /></LayoutSidebar>
              ) : (
                <Navigate to="/" />
              )
            }
          />
        )}
        {hasPermission('MANAGEMENT_USER_CREATE') && (
          <Route
            path="/StaffForm"
            element={
              isAuthenticated ? (
                <LayoutSidebar><StaffForm handleLogout={handleLogout} /></LayoutSidebar>
              ) : (
                <Navigate to="/" />
              )
            }
          />
        )}
        {hasPermission('MANAGEMENT_USER_UPDATE') && (
          <Route path="/StaffForm/:userCode"
            element={
              isAuthenticated ? (
                <LayoutSidebar><StaffForm handleLogout={handleLogout} /></LayoutSidebar>
              ) : (
                <Navigate to="/" />
              )
            }
          />
        )}
        {hasPermission('PERMISSION_LIST') && (
          <Route
            path="/PermissionsList"
            element={
              isAuthenticated ? (
                <LayoutSidebar><PermissionsList handleLogout={handleLogout} /></LayoutSidebar>
              ) : (
                <Navigate to="/" />
              )
            }
          />
        )}
        {hasPermission('PERMISSION_CREATE') && (
          <Route
            path="/PermissionFrom"
            element={
              isAuthenticated ? (
                <LayoutSidebar><PermissionFrom handleLogout={handleLogout} /></LayoutSidebar>
              ) : (
                <Navigate to="/" />
              )
            }
          />
        )}
        {hasPermission('PERMISSION_UPDATE') && (
          <Route
            path="/PermissionFrom/:id"
            element={
              isAuthenticated ? (
                <LayoutSidebar><PermissionFrom handleLogout={handleLogout} /></LayoutSidebar>
              ) : (
                <Navigate to="/" />
              )
            }
          />
        )}
        {hasPermission('ROLE_LIST') && (
          <Route
            path="/RolList"
            element={
              isAuthenticated ? (
                <LayoutSidebar><RolList handleLogout={handleLogout} /></LayoutSidebar>
              ) : (
                <Navigate to="/" />
              )
            }
          />
        )}
        {hasPermission('ROLE_CREATE') && (
          <Route
            path="/RolForm"
            element={
              isAuthenticated ? (
                <LayoutSidebar><RolForm handleLogout={handleLogout} /></LayoutSidebar>
              ) : (
                <Navigate to="/" />
              )
            }
          />
        )}
        {hasPermission('ROLE_UPDATE') && (
          <Route
            path="/RolForm/:id"
            element={
              isAuthenticated ? (
                <LayoutSidebar><RolForm handleLogout={handleLogout} /></LayoutSidebar>
              ) : (
                <Navigate to="/" />
              )
            }
          />
        )}
        {hasPermission('DISTRIBUTOR_LIST') && (
          <Route
            path="/DistributorsList"
            element={
              isAuthenticated ? (
                <LayoutSidebar><DistributorsList handleLogout={handleLogout} /></LayoutSidebar>
              ) : (
                <Navigate to="/" />
              )
            }
          />
        )}
        {hasPermission('DISTRIBUTOR_CREATE') && (
          <Route
            path="/DistributorsForm"
            element={
              isAuthenticated ? (
                <LayoutSidebar><DistributorsForm handleLogout={handleLogout} /></LayoutSidebar>
              ) : (
                <Navigate to="/" />
              )
            }
          />
        )}
        {hasPermission('DISTRIBUTOR_UPDATE') && (
          <Route
            path="/DistributorsForm/:codeDistributor"
            element={
              isAuthenticated ? (
                <LayoutSidebar><DistributorsForm handleLogout={handleLogout} /></LayoutSidebar>
              ) : (
                <Navigate to="/" />
              )
            }
          />
        )}
        {hasPermission('CREDENTIAL_LIST') && (
          <Route
            path="/CredentialsList"
            element={
              isAuthenticated ? (
                <LayoutSidebar><CredentialsList handleLogout={handleLogout} /></LayoutSidebar>
              ) : (
                <Navigate to="/" />
              )
            }
          />
        )}
        {hasPermission('CREDENTIAL_CREATE') && (
          <Route
            path="/CredentialsForm"
            element={
              isAuthenticated ? (
                <LayoutSidebar><CredentialsForm handleLogout={handleLogout} /></LayoutSidebar>
              ) : (
                <Navigate to="/" />
              )
            }
          />
        )}
        {hasPermission('CREDENTIAL_UPDATE') && (
          <Route
            path="/CredentialsForm/:id"
            element={
              isAuthenticated ? (
                <LayoutSidebar><CredentialsForm handleLogout={handleLogout} /></LayoutSidebar>
              ) : (
                <Navigate to="/" />
              )
            }
          />
        )}
        {hasPermission('PARAMETER_LIST') && (
          <Route
            path="/ParametersList"
            element={
              isAuthenticated ? (
                <LayoutSidebar><ParametersList handleLogout={handleLogout} /></LayoutSidebar>
              ) : (
                <Navigate to="/" />
              )
            }
          />
        )}
        {hasPermission('PARAMETER_CREATE') && (
          <Route
            path="/ParametersForm"
            element={
              isAuthenticated ? (
                <LayoutSidebar><ParametersForm handleLogout={handleLogout} /></LayoutSidebar>
              ) : (
                <Navigate to="/" />
              )
            }
          />
        )}
        {hasPermission('PARAMETER_UPDATE') && (
          <Route
            path="/ParametersForm/:id"
            element={
              isAuthenticated ? (
                <LayoutSidebar><ParametersForm handleLogout={handleLogout} /></LayoutSidebar>
              ) : (
                <Navigate to="/" />
              )
            }
          />
        )}
        {hasPermission('USER_LIST') && (
          <Route
            path="/UsersList"
            element={
              isAuthenticated ? (
                <LayoutSidebar><UsersList handleLogout={handleLogout} /></LayoutSidebar>
              ) : (
                <Navigate to="/" />
              )
            }
          />
        )}
        {hasPermission('USER_CREATE') && (
          <Route
            path="/UsersForm"
            element={
              isAuthenticated ? (
                <LayoutSidebar><UsersForm handleLogout={handleLogout} /></LayoutSidebar>
              ) : (
                <Navigate to="/" />
              )
            }
          />
        )}
        {hasPermission('USER_UPDATE') && (
          <Route
            path="/UsersForm/:id"
            element={
              isAuthenticated ? (
                <LayoutSidebar><UsersForm handleLogout={handleLogout} /></LayoutSidebar>
              ) : (
                <Navigate to="/" />
              )
            }
          />
        )}
        {hasPermission('COMPANY_LIST') && (
          <Route
            path="/CompaniesList"
            element={
              isAuthenticated ? (
                <LayoutSidebar><CompaniesList handleLogout={handleLogout} /></LayoutSidebar>
              ) : (
                <Navigate to="/" />
              )
            }
          />
        )}
        {hasPermission('COMPANY_CREATE') && (
          <Route
            path="/CompaniesForm"
            element={
              isAuthenticated ? (
                <LayoutSidebar><CompaniesForm handleLogout={handleLogout} /></LayoutSidebar>
              ) : (
                <Navigate to="/" />
              )
            }
          />
        )}
        {hasPermission('COMPANY_UPDATE') && (
          <Route
            path="/CompaniesForm/:id"
            element={
              isAuthenticated ? (
                <LayoutSidebar><CompaniesForm handleLogout={handleLogout} /></LayoutSidebar>
              ) : (
                <Navigate to="/" />
              )
            }
          />
        )}
         {hasPermission('CREDENTIAL_KPI_CREATE') && (
          <Route
            path="/ConnecctionKpiForm"
            element={
              isAuthenticated ? (
                <LayoutSidebar><ConnecctionKpiForm handleLogout={handleLogout} /></LayoutSidebar>
              ) : (
                <Navigate to="/" />
              )
            }
          />
        )}
        {hasPermission('CUSTOMER_MANAGEMENT') && (
          <Route
            path="/CustomerManagement"
            element={
              isAuthenticated ? (
                <LayoutSidebar><CustomerManagement handleLogout={handleLogout} /></LayoutSidebar>
              ) : (
                <Navigate to="/" />
              )
            }
          />
        )}
        {hasPermission('CONTRACT_REASSIGN') && (
          <Route
            path="/ContractReassignment"
            element={
              isAuthenticated ? (
                <LayoutSidebar><ContractReassignment handleLogout={handleLogout} /></LayoutSidebar>
              ) : (
                <Navigate to="/" />
              )
            }
          />
        )}
        {hasPermission('DISTRIBUTOR_REASSIGN') && (
          <Route
            path="/DistributorReassignment"
            element={
              isAuthenticated ? (
                <LayoutSidebar><DistributorReassignment handleLogout={handleLogout} /></LayoutSidebar>
              ) : (
                <Navigate to="/" />
              )
            }
          />
        )}
        {hasPermission('COMPANY_LIST') && (
          <Route
            path="/CompaniesManagement"
            element={
              isAuthenticated ? (
                <LayoutSidebar><CompaniesManagement handleLogout={handleLogout} /></LayoutSidebar>
              ) : (
                <Navigate to="/" />
              )
            }
          />
        )}
        {role === 'SUPER_ADMIN' && (
          <Route
            path="/TechnicianList"
            element={
              isAuthenticated ? (
                <LayoutSidebar><TechnicianList handleLogout={handleLogout} /></LayoutSidebar>
              ) : (
                <Navigate to="/" />
              )
            }
          />
        )}
        {role === 'SUPER_ADMIN' && (
          <Route
            path="/TechnicianForm"
            element={
              isAuthenticated ? (
                <LayoutSidebar><TechnicianForm handleLogout={handleLogout} /></LayoutSidebar>
              ) : (
                <Navigate to="/" />
              )
            }
          />
        )}
        {role === 'SUPER_ADMIN' && (
          <Route
            path="/TechnicianForm/:id"
            element={
              isAuthenticated ? (
                <LayoutSidebar><TechnicianForm handleLogout={handleLogout} /></LayoutSidebar>
              ) : (
                <Navigate to="/" />
              )
            }
          />
        )}
        {(role === 'SUPER_ADMIN' || role === 'ADMIN')  && (
          <Route
            path="/ServerList"
            element={
              isAuthenticated ? (
                <LayoutSidebar><ServerList handleLogout={handleLogout} /></LayoutSidebar>
              ) : (
                <Navigate to="/" />
              )
            }
          />
        )}
        {(role === 'SUPER_ADMIN' || role === 'ADMIN')  && (
          <Route
            path="/ServerForm"
            element={
              isAuthenticated ? (
                <LayoutSidebar><ServerForm handleLogout={handleLogout} /></LayoutSidebar>
              ) : (
                <Navigate to="/" />
              )
            }
          />
        )}
        {(role === 'SUPER_ADMIN' || role === 'ADMIN')  && (
          <Route
            path="/ServerForm/:id"
            element={
              isAuthenticated ? (
                <LayoutSidebar><ServerForm handleLogout={handleLogout} /></LayoutSidebar>
              ) : (
                <Navigate to="/" />
              )
            }
          />
        )}
        {hasPermission('CREDENTIAL_KPI_LIST') && (
          <Route
            path="/ConnectionKpiList"
            element={
              isAuthenticated ? (
                <LayoutSidebar><ConnectionKpiList handleLogout={handleLogout} /></LayoutSidebar>
              ) : (
                <Navigate to="/" />
              )
            }
          />
        )}
        {hasPermission('CREDENTIAL_KPI_UPDATE') && (
          <Route
            path="/ConnecctionKpiForm/:id"
            element={
              isAuthenticated ? (
                <LayoutSidebar><ConnecctionKpiForm handleLogout={handleLogout} /></LayoutSidebar>
              ) : (
                <Navigate to="/" />
              )
            }
          />
        )}
        <Route
          path="/reset_password/:token"
          element={<ResetPassword handleLogout={handleLogout} />}
        />
        <Route path="*" element={<Navigate to="/Home" />} />
      </Routes>
    </Router>
  );
}

export default App;