import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import Section from "../../components/Section";
import Loader from "../../components/Loader";
import SuccessNotification from "../../components/Notifications/SuccessNotification";
import ErrorNotification from "../../components/Notifications/ErrorNotification";
import LoginNotification from "../../components/Notifications/LoginNotification";
import WarningNotification from "../../components/Notifications/WarningNotification";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSave,
  faRotate,
  faCircleArrowLeft,
  faXmark,
  faEyeSlash,
  faEye
} from "@fortawesome/free-solid-svg-icons";
import apiClient from "../../axios";

const StaffForm = ({ handleLogout }) => {
  const navigate = useNavigate();
  const { userCode } = useParams();
  const [loading, setLoading] = useState(false);
  const [isWarningVisible, setIsWarningVisible] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [distributors, setDistributors] = useState([]);
  const [code, setCodigo] = useState("");
  const [cif, setCif] = useState("");
  const [name, setNombre] = useState("");
  const [phone, setTelefono] = useState("");
  const [cellPhone, setCelular] = useState("");
  const [password, setClave] = useState("");
  const role = localStorage.getItem('role');
  const distributorToken = localStorage.getItem('distributor');
  const [distributor, setCanal] = useState(role !== 'SUPER_ADMIN' && role !== 'ADMIN' ? distributorToken : distributorToken);
  const [isSuccessVisible, setIsSuccessVisible] = useState(false);
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [errorsMessage, setErrorMessage] = useState({});
  const [isLoginVisible, setIsLoginVisible] = useState(false);
  const [errors, setErrors] = useState({});
  const contract = process.env.REACT_APP_FENIX_CONTRACT;
  const [showPassword, setShowPassword] = useState(false);
  const [confirmarClave, setConfirmarClave] = useState("");
  const [showPassword2, setShowPassword2] = useState(false);
  const [email, setEmail] = useState("");
  const [roleId, setRoleId] = useState('');
  const [rolList, setRolList] = useState([]);

  useEffect(() => {
    if (userCode) {
      const fetchProduct = async () => {
        try {
          const response = await apiClient.get(`/admin/staff/${userCode}`);
          setCodigo(response.data.percodigo);
          setNombre(response.data.pernombre);
          setCif(response.data.percedula);
          setTelefono(response.data.pertelf);
          setCelular(response.data.percelular);
          setCanal(response.data.distributorCode);
          setRoleId(response.data.roleId);
          setEmail(response.data.email);
        } catch (error) {
          console.error("Error al cargar el usuario", error);
        }
      };
      fetchProduct();
    }
  }, [userCode]);

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await apiClient.get(`/admin/roles`);
        if (Array.isArray(response.data)) {
          setRolList(response.data);
        } else {
          setRolList([response.data]);
        }
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };

    fetchRoles();
  }, []);

  const fetchAuthToken = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_CIFRUC_URL}/login`,
        {
          method: "GET",
          headers: {
            Authorization: "Basic " + btoa("fenix:7&m$T8v_12d6?aA}yVOF"),
          },
        }
      );

      if (!response.ok) {
        throw new Error("Error al obtener el token");
      }

      const data = await response.json();
      return data.token;
    } catch (error) {
      console.error("Error al obtener el token:", error);
      return null;
    }
  };
  // Nueva función para manejar la búsqueda
  const handleDocumentSearch = async (event) => {
    if (event.key === "Enter" || event.key === "Tab") {
      event.preventDefault();
      try {
        // Obtén el token primero
        setLoading(true);
        const token = await fetchAuthToken();
        if (!token) {
          throw new Error("No se pudo obtener el token de autenticación");
        }

        const response = await fetch(
          `${process.env.REACT_APP_CIFRUC_URL}/document/search`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              contract: contract,
              document: cif,
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Error en la búsqueda del documento");
        }
        const data = await response.json();

        if (data.document && data.document.socialReason) {
          setIsWarningVisible(false);
          setIsErrorVisible(false);
          setIsButtonDisabled(false);
          setNombre(data.document.socialReason);
        }
        if (data.document && data.document.mobile) {
          setTelefono(data.document.mobile);
        } else {
          setTelefono("");
        }
      } catch (error) {
        if (cif.length === 10 && /^[0-9]+$/.test(cif)) {
          setErrorMessage(
            "El Documento de Identificación ingresado es Incorrecto o No Existe."
          );
          setIsErrorVisible(true);
          setIsButtonDisabled(true);
        } else if (cif.length === 13 && /^[0-9]+$/.test(cif)) {
          setErrorMessage(
            "El Documento de Identificación ingresado es Incorrecto o No Existe."
          );
          setIsErrorVisible(true);
          setIsButtonDisabled(true);
        } else if (/[^0-9]/.test(cif)) {
          setWarningMessage(
            "El Documento de Identificación ingresado es un Pasaporte."
          );
          setIsWarningVisible(true);
          setIsButtonDisabled(false);
        }
        setClave("");
        setCodigo("");
        setNombre("");
        setTelefono("");
        setCelular("");
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    // Solo ejecuta fetchDistributors si el role es ADMIN o SUPER_ADMIN
    if (role === 'ADMIN' || role === 'SUPER_ADMIN') {
      const fetchDistributors = async () => {
        try {
          const response = await apiClient.get("/distributors");
          setDistributors(response.data);
        } catch (error) {
          console.error("Error fetching distributors:", error);
        }
      };

      fetchDistributors();
    }
  }, [role]);

  const toggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const toggleShowPassword2 = () => {
    setShowPassword2((prevShowPassword) => !prevShowPassword);
  };

  const validateEmail = (email) => {
    // Expresión regular para validar el formato de email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email); // Solo valida el formato del correo
  };
  const handleChangeEmail = (e) => {
    const value = e.target.value;
    setEmail(value);

    // Validar el email
    if (!validateEmail(value)) {
      setErrors({
        email: 'Email inválido. Debe contener "@" y un dominio válido.',
      });
    } else {
      setErrors({});
    }
  };
  const validateFields = () => {
    let validationErrors = {};
    const onlyLetters = /^[a-zA-Z]+$/;
    if (!code) {
      validationErrors.code = "El Campo es Obligatorio";
    } else if (!onlyLetters.test(code)) {
      validationErrors.code = "Solo se permiten letras";
    }
    if (!name) validationErrors.name = "El Campo es Obligatorio";
    if (!cif) validationErrors.cif = "El Campo es Obligatorio";
    if (!password) validationErrors.password = "El Campo es Obligatorio";
    if (!phone) validationErrors.phone = "El Campo es Obligatorio";
    if (!cellPhone) validationErrors.cellPhone = "El Campo es Obligatorio";
    if (!email) validationErrors.email = "El Campo es Obligatorio";
    if (!distributor)
      validationErrors.distributor = "El Campo es Obligatorio";
    if (password !== confirmarClave) {
      validationErrors.confirmarClave = "Las claves no coinciden";
    }
    return validationErrors;
  };

  const handleCifChange = (e) => {
    const value = e.target.value;
    setCif(value);

    if (value === "") {
      setClave("");
      setCodigo("");
      setNombre("");
      setCelular("");
      setTelefono("");
      setIsWarningVisible(false);
      setIsErrorVisible(false);
      setIsButtonDisabled(false);
    }
  };

  const handleSave = async () => {
    const validationErrors = validateFields();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    try {
      let response;
      if (userCode) {
        const payloadUpdate = {
          name,
          cif,
          password,
          phone,
          cellPhone,
          distributor,
          roleId,
          email
        };
        response = await apiClient.patch(
          `/admin/staff/${userCode}`,
          payloadUpdate
        );
        if (response.status === 200) {
          setIsSuccessVisible(true);
          setIsErrorVisible(false);
          setIsLoginVisible(false);
          setErrors({});
          setTimeout(() => {
            setIsSuccessVisible(false);
          }, 4000);
          navigate("/StaffList");
        }
      } else {
        const payload = {
          code,
          name,
          cif,
          password,
          phone,
          cellPhone,
          distributor,
          roleId,
          email
        };
        response = await apiClient.post("/admin/staff", payload);
        if (response.status === 201) {
          setIsSuccessVisible(true);
          setIsErrorVisible(false);
          setErrors({});
          setTimeout(() => {
            setIsSuccessVisible(false);
          }, 4000);
          navigate("/StaffList");
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setIsLoginVisible(true);
      } else if (error.response?.data?.message === "USER_ALREADY_EXISTS") {
        setErrorMessage("El Usuario ya existe.");
        setIsLoginVisible(false);
        setIsErrorVisible(true);
        setIsSuccessVisible(false);
        setTimeout(() => {
          setIsErrorVisible(false);
        }, 4000);
      }
      else {
        setErrorMessage("Oops!. Ocurrio un error al crear el Usario");
        setIsLoginVisible(false);
        setIsErrorVisible(true);
        setIsSuccessVisible(false);
        setTimeout(() => {
          setIsErrorVisible(false);
        }, 4000);
      }
    }
  };

  return (
    <div className="home-container">
      <Header onLogout={handleLogout} title="Personal" />
      <Section>
        <div className="button-return-container">
          <FontAwesomeIcon
            className="basic-shortcut-icon"
            style={{ cursor: "pointer" }}
            icon={faCircleArrowLeft}
            onClick={(e) => {
              e.stopPropagation();
              navigate("/StaffList");
            }}
          />
        </div>
      </Section>
      <div className="moduleNew-form-container">
        <h3 className="basic-info-form-title">Información del Personal</h3>
        <div className="basic-info-form-grid">
          <div className="basic-info-form-group">
            <label style={{ color: errors.code ? "red" : "inherit" }}>
              Código de Usuario
            </label>
            <input
              type="text"
              placeholder="Código"
              value={code}
              onChange={(e) => setCodigo(e.target.value)}
            />
            {errors.code && <p className="error-message">{errors.code}</p>}
          </div>
          <div className="basic-info-form-group">
            <label style={{ color: errors.cif ? "red" : "inherit" }}>
              Documento de Identificación
            </label>
            <input
              type="text"
              placeholder="Documento de Identificación"
              value={cif}
              onChange={handleCifChange}
              onKeyDown={handleDocumentSearch}
              readOnly={!!userCode}
            />
            {errors.cif && <p className="error-message">{errors.cif}</p>}
          </div>
          <div className="basic-info-form-group">
            <label style={{ color: errors.name ? "red" : "inherit" }}>
              Nombre
            </label>
            <input
              type="text"
              placeholder="Nombre"
              value={name}
              onChange={(e) => setNombre(e.target.value)}
            />
            {errors.name && <p className="error-message">{errors.name}</p>}
          </div>
          <div className="basic-info-form-group">
            <label>Rol</label>
            <select
              value={roleId}
              onChange={(e) => setRoleId(e.target.value)}
            >
              <option value="">Seleccione un Rol</option>
              {rolList.map((rol) => (
                <option key={rol.id} value={rol.id}>
                  {rol.description}
                </option>
              ))}
            </select>
          </div>
          <div className="basic-info-form-group">
            <label style={{ color: errors.phone ? "red" : "inherit" }}>
              Teléfono
            </label>
            <input
              type="text"
              placeholder="Teléfono"
              value={phone}
              onChange={(e) => setTelefono(e.target.value)}
            />
            {errors.phone && <p className="error-message">{errors.phone}</p>}
          </div>
          <div className="basic-info-form-group">
            <label style={{ color: errors.cellPhone ? "red" : "inherit" }}>
              Celular
            </label>
            <input
              type="text"
              placeholder="Celular"
              value={cellPhone}
              onChange={(e) => setCelular(e.target.value)}
            />
            {errors.cellPhone && <p className="error-message">{errors.cellPhone}</p>}
          </div>
          <div className="basic-info-form-group">
            <label style={{ color: errors.distributor ? "red" : "inherit" }}>
              Distribuidor
            </label>
            {role === 'SUPER_ADMIN' || role === 'ADMIN' ? (
              <select
                value={distributor}
                onChange={(e) => setCanal(e.target.value)}
              >
                <option value="">Seleccione un Distribuidor</option>
                {distributors.map((distributor) => (
                  <option key={distributor.code} value={distributor.code}>
                    {distributor.name}
                  </option>
                ))}
              </select>
            ) : (
              <input
                type="text"
                value={distributor}
                readOnly
              />
            )}
            {errors.distributor && (
              <p className="error-message">{errors.distributor}</p>
            )}
          </div>
          <div className="basic-info-form-group">
            <label style={{ color: errors.email ? "red" : "inherit" }}>
              Email
            </label>
            <input
              type="text"
              placeholder="Email"
              value={email}
              onChange={handleChangeEmail}
            />
            {errors.email && <p className="error-message">{errors.email}</p>}
          </div>
          <div className="basic-info-form-group">
            <label style={{ color: errors.password ? "red" : "inherit" }}>
              Clave
            </label>
            <div className="password-input-container">
              <input
                className="text-box-login2"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setClave(e.target.value)}
                placeholder="Contraseña"
              />
              <FontAwesomeIcon
                icon={showPassword ? faEyeSlash : faEye}
                className="password-toggle-icon2"
                onClick={toggleShowPassword}
              />
            </div>
            {errors.password && <p className="error-message">{errors.password}</p>}
          </div>
          <div className="basic-info-form-group">
            <label style={{ color: errors.confirmarClave ? "red" : "inherit" }}>
              Confirmar Clave
            </label>
            <div className="password-input-container">
              <input
                type={"password"}
                placeholder="Confirmar Clave"
                value={confirmarClave}
                onChange={(e) => setConfirmarClave(e.target.value)}
              />
              <FontAwesomeIcon
                icon={showPassword2 ? faEyeSlash : faEye}
                className="password-toggle-icon2"
                onClick={toggleShowPassword2}
              />
            </div>
            {errors.confirmarClave && <p className="error-message">{errors.confirmarClave}</p>}
          </div>
        </div>
        <div className="basic-form-footer">
          <button
            className="basic-custom-button"
            onClick={(e) => {
              e.stopPropagation();
              navigate("/StaffList");
            }}
          >
            <FontAwesomeIcon icon={faXmark} className="basic-shortcut-icon" />
            Cancelar
          </button>
          <button
            className="basic-custom-button"
            onClick={handleSave}
            disabled={isButtonDisabled}
          >
            <FontAwesomeIcon
              icon={userCode ? faRotate : faSave}
              className="basic-shortcut-icon"
            />
            {userCode ? "Actualizar" : "Guardar"}
          </button>
        </div>
      </div>
      <SuccessNotification
        message={
          userCode
            ? "El Usuario se ha Actualizado Correctamente."
            : "El Usuario se ha Creado Correctamente."
        }
        isVisible={isSuccessVisible}
        onClose={() => setIsSuccessVisible(false)}
      />
      <ErrorNotification
        message={errorsMessage}
        isVisible={isErrorVisible}
        onClose={() => setIsErrorVisible(false)}
      />
      <WarningNotification
        message={warningMessage}
        isVisible={isWarningVisible}
        onClose={() => setIsWarningVisible(false)}
      />
      <LoginNotification
        message="Vuele a iniciar sesión"
        isVisible={isLoginVisible}
        onClose={() => setIsLoginVisible(false)}
        onLogout={handleLogout}
      />
      {loading && <Loader />}
    </div>
  );
};

export default StaffForm;
