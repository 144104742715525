import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import Section from "../../components/Section";
import SuccessNotification from "../../components/Notifications/SuccessNotification";
import ErrorNotification from "../../components/Notifications/ErrorNotification";
import LoginNotification from "../../components/Notifications/LoginNotification";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faSave,
    faRotate,
    faCircleArrowLeft,
    faXmark,
    faEyeSlash,
    faEye,
} from "@fortawesome/free-solid-svg-icons";
import apiClient from "../../axios";

const TechnicianForm = ({ handleLogout }) => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [description, setDescription] = useState("");
    const [contract, setContract] = useState("");
    const [max_user, setUsuario] = useState("");
    const [flag_active, setFlagActive] = useState(false);
    const [ip_access, setIpAccess] = useState("");
    const [url_access, setUrlAccess] = useState("");
    const [port_access, setpPortAccess,] = useState("");
    const [user_manager, setUserManager,] = useState("");
    const [pass_manager, setPassManager,] = useState("");
    const [isSuccessVisible, setIsSuccessVisible] = useState(false);
    const [isErrorVisible, setIsErrorVisible] = useState(false);
    const [errorsMessage, setErrorMessage] = useState({});
    const [isLoginVisible, setIsLoginVisible] = useState(false);
    const [errors, setErrors] = useState({});
    const [showPassword, setShowPassword] = useState(false);


    useEffect(() => {
        if (id) {
            const fetchProduct = async () => {
                try {
                    const response = await apiClient.get(`/servers/${id}`);
                    setDescription(response.data.description);
                    setContract(response.data.contract);
                    setUsuario(response.data.maxUser);
                    setFlagActive(response.data.flagActive);

                } catch (error) {
                    console.error("Error al cargar el Servidor", error);
                }
            };
            fetchProduct();
        }
    }, [id]);

    /*  const toggleSwitch = () => {
          setFlagActive((prev) => !prev);
      };*/

    const toggleShowPassword = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };
    const validateFields = () => {
        let validationErrors = {};
        if (!description) validationErrors.description = "El Campo es Obligatorio";
        if (!max_user) validationErrors.max_user = "El Campo es Obligatorio";
        return validationErrors;
    };

    const handleSave = async () => {
        const validationErrors = validateFields();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
        try {
            let response;
            if (id) {
                const payloadUpdate = {
                    description,
                    max_user,
                    ip_access,
                    url_access,
                    port_access,
                    user_manager,
                    pass_manager
                };
                response = await apiClient.patch(
                    `/servers/${id}`,
                    payloadUpdate
                );
                if (response.status === 200) {
                    setIsSuccessVisible(true);
                    setIsErrorVisible(false);
                    setIsLoginVisible(false);
                    setErrors({});
                    setTimeout(() => {
                        setIsSuccessVisible(false);
                    }, 4000);
                    navigate("/ServerList");
                }
            } else {
                const payload = {
                    description,
                    contract,
                    max_user,
                    flag_active,
                    ip_access,
                    url_access,
                    port_access,
                    user_manager,
                    pass_manager
                };
                response = await apiClient.post("/servers", payload);
                if (response.status === 201) {
                    setIsSuccessVisible(true);
                    setIsErrorVisible(false);
                    setErrors({});
                    setTimeout(() => {
                        setIsSuccessVisible(false);
                    }, 4000);
                    navigate("/ServerList");
                }
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                setIsLoginVisible(true);
            } else if (error.response?.data?.message.startsWith("CONTRACT_ALREADY_IN_USE")) {
                setErrorMessage("El Nro.Contrato ingresado ya esta asociado a un Servidor.");
                setIsLoginVisible(false);
                setIsErrorVisible(true);
                setIsSuccessVisible(false);
                setTimeout(() => {
                    setIsErrorVisible(false);
                }, 4000);
            } else if (error.response?.data?.message.startsWith("\"max_user\" must be less than or equal to 99")) {
                setErrorMessage("El Nro. de Usarios maximos es de 99.");
                setIsLoginVisible(false);
                setIsErrorVisible(true);
                setIsSuccessVisible(false);
                setTimeout(() => {
                    setIsErrorVisible(false);
                }, 4000);
            }
            else {
                setErrorMessage("Oops!. Ocurrio un error al crear el Servidor");
                setIsLoginVisible(false);
                setIsErrorVisible(true);
                setIsSuccessVisible(false);
                setTimeout(() => {
                    setIsErrorVisible(false);
                }, 4000);
            }
        }
    };

    return (
        <div className="home-container">
            <Header onLogout={handleLogout} title="Servidor" />
            <Section>
                <div className="button-return-container">
                    <FontAwesomeIcon
                        className="basic-shortcut-icon"
                        style={{ cursor: "pointer" }}
                        icon={faCircleArrowLeft}
                        onClick={(e) => {
                            e.stopPropagation();
                            navigate("/ServerList");
                        }}
                    />
                </div>
            </Section>
            <div className="moduleNew-form-container">
                <h3 className="basic-info-form-title">Información del Servidor</h3>
                <div className="basic-info-form-grid">
                    <div className="basic-info-form-group">
                        <label style={{ color: errors.description ? "red" : "inherit" }}>
                            Descripción
                        </label>
                        <input
                            type="text"
                            placeholder="Descripción"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}

                        />
                        {errors.description && <p className="error-message">{errors.description}</p>}
                    </div>
                    <div className="basic-info-form-group">
                        <label>
                            Nro. Contrato
                        </label>
                        <input
                            type="number"
                            placeholder="Nro. Contrato"
                            value={contract}
                            onChange={(e) => setContract(e.target.value)}
                            readOnly={!!id}
                        />
                    </div>
                    <div className="basic-info-form-group">
                        <label style={{ color: errors.max_user ? "red" : "inherit" }}>
                            Nro. Usuarios
                        </label>
                        <input
                            type="number"
                            placeholder="Nro. Usuarios"
                            value={max_user}
                            onChange={(e) => setUsuario(e.target.value)}
                        />
                        {errors.max_user && <p className="error-message">{errors.max_user}</p>}
                    </div>
                    <div className="basic-info-form-group">
                        <label>
                            Ip de Acceso
                        </label>
                        <input
                            type="text"
                            placeholder="Ip de Acceso"
                            value={ip_access}
                            onChange={(e) => setIpAccess(e.target.value)}
                        />
                    </div>
                    <div className="basic-info-form-group">
                        <label>
                            URL de Acceso
                        </label>
                        <input
                            type="text"
                            placeholder="URL de Acceso"
                            value={url_access}
                            onChange={(e) => setUrlAccess(e.target.value)}
                        />
                    </div>
                    <div className="basic-info-form-group">
                        <label>
                            Puerto de Acceso
                        </label>
                        <input
                            type="text"
                            placeholder="Puerto de Acceso"
                            value={port_access}
                            onChange={(e) => setpPortAccess(e.target.value)}
                        />
                    </div>
                    <div className="basic-info-form-group">
                        <label>
                            Usuario de Adminitrador
                        </label>
                        <input
                            type="text"
                            placeholder="Usuario"
                            value={user_manager}
                            onChange={(e) => setUserManager(e.target.value)}
                        />
                    </div>
                    <div className="basic-info-form-group">
                        <label>
                            Clave de Adminitrador
                        </label>
                        <div className="password-input-container">
                            <input
                                className="text-box-login2"
                                type={showPassword ? "text" : "password"}
                                value={pass_manager}
                                onChange={(e) => setPassManager(e.target.value)}
                                placeholder="Contraseña"
                            />
                            <FontAwesomeIcon
                                icon={showPassword ? faEyeSlash : faEye}
                                className="password-toggle-icon2"
                                onClick={toggleShowPassword}
                            />
                        </div>
                    </div>
                    {/* <div className="basic-info-form-group">
                        <div className="basic-info-form-switch">
                            <label>Estado</label>
                            <div className="slider-container-contract" onClick={toggleSwitch}>
                                <div
                                    className={`slider-option-contract ${flag_active
                                        ? "active-contract"
                                        : "inactive-contract"
                                        }`}
                                >
                                    Activo
                                </div>
                                <div
                                    className={`slider-option-contract ${!flag_active
                                        ? "active-contract"
                                        : "inactive-contract"
                                        }`}
                                >
                                    Inactivo
                                </div>
                            </div>
                        </div>
                    </div>*/}
                </div>
                <div className="basic-form-footer">
                    <button
                        className="basic-custom-button"
                        onClick={(e) => {
                            e.stopPropagation();
                            navigate("/ServerList");
                        }}
                    >
                        <FontAwesomeIcon icon={faXmark} className="basic-shortcut-icon" />
                        Cancelar
                    </button>
                    <button
                        className="basic-custom-button"
                        onClick={handleSave}
                    >
                        <FontAwesomeIcon
                            icon={id ? faRotate : faSave}
                            className="basic-shortcut-icon"
                        />
                        {id ? "Actualizar" : "Guardar"}
                    </button>
                </div>
            </div>
            <SuccessNotification
                message={
                    id
                        ? "El Servidor se ha Actualizado Correctamente."
                        : "El Servidor se ha Creado Correctamente."
                }
                isVisible={isSuccessVisible}
                onClose={() => setIsSuccessVisible(false)}
            />
            <ErrorNotification
                message={errorsMessage}
                isVisible={isErrorVisible}
                onClose={() => setIsErrorVisible(false)}
            />
            <LoginNotification
                message="Vuele a iniciar sesión"
                isVisible={isLoginVisible}
                onClose={() => setIsLoginVisible(false)}
                onLogout={handleLogout}
            />

        </div>
    );
};

export default TechnicianForm;
