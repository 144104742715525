import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Header from "../../components/Header";
import Section from "../../components/Section";
import SuccessNotification from "../../components/Notifications/SuccessNotification";
import ErrorNotification from "../../components/Notifications/ErrorNotification";
import LoginNotification from "../../components/Notifications/LoginNotification";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faSave,
    faRotate,
    faCircleArrowLeft,
    faXmark
} from "@fortawesome/free-solid-svg-icons";
import apiClient from "../../axios";
import Loader from "../../components/Loader";
import NotificationConnectionKpi from '../../components/Notifications/NotificationConnectionKpi';

const ConnecctionKpiForm = ({ handleLogout }) => {
    const navigate = useNavigate();
    const location = useLocation();
    //const { token } = location.state || {};
    const { companyId } = location.state || {};
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const { numCont } = location.state || {};
    const [module, setModule] = useState([]);
    const [moduleId, setModuleId] = useState("");
    const [engine, setEngine] = useState("");
    const [dbName, setDbName] = useState("");
    const [dbPass, setDbPass] = useState("");
    const [dbHost, setDbHost] = useState("");
    const [dbPort, setDbPort] = useState(0);
    const [dbUser, setDbUser] = useState("");
    const [flagActive, setCheckObservacion] = useState(0);
    const [isSuccessVisible, setIsSuccessVisible] = useState(false);
    const [isErrorVisible, setIsErrorVisible] = useState(false);
    const [errorsMessage, setErrorMessage] = useState({});
    const [isLoginVisible, setIsLoginVisible] = useState(false);
    const [errors, setErrors] = useState({});
    const [mensaje, setMensaje] = useState(0);

    const [isSuccessNotifVisible, setIsSuccessNotifVisible] = useState(false);
    const [contractId, setContractId] = useState([]);
    const [companieId, setCompanieId] = useState([]);

    useEffect(() => {
        if (id) {
            const fetchConnection = async () => {
                try {
                    setLoading(true);
                    //const response = await apiClient.get(`/${numCont}/connectionToken/${id}`);
                    const response = await apiClient.get(`/connectionToken/${id}`);
                    if (response.status === 200) {
                        setContractId(numCont);
                        setCompanieId(companyId);
                        setModuleId(response.data.moduleId);
                        setEngine(response.data.engine);
                        setDbName(response.data.dbName);
                        setDbPass(response.data.dbPass);
                        setDbHost(response.data.dbHost);
                        setDbPort(response.data.dbPort);
                        setDbUser(response.data.dbUser);
                        setCheckObservacion(response.data.flagActive ? 1 : 0);
                        console.log("hola");
                    }
                } catch (error) {
                    console.error("Error al cargar la Conexión", error);
                } finally {
                    setLoading(false);
                }
            };
            fetchConnection();
        }
        const fetchModules = async () => {
            try {
                setLoading(true);
                setContractId(numCont);
                setCompanieId(companyId);
                //const module = await apiClient.post(`/contracts/${contract}/modules`);
                const modules = await apiClient.get(`/contracts/${numCont}/modules`);
                if (modules.status === 200) {
                    setModule(modules.data || []);
                }
            } catch (error) {
                console.error("Error al cargar el Módulo", error);
            } finally {
                setLoading(false);
            }
        };
        fetchModules();
    }, [companyId, id, numCont]);

    const toggleSwitch = () => {
        setCheckObservacion((prev) => (prev === 1 ? 0 : 1));
    };

    const validateFields = () => {
        let validationErrors = {};
        if (!moduleId) validationErrors.moduleId = "El Campo es Obligatorio";
        if (!engine) validationErrors.engine = "El Campo es Obligatorio";
        if (!dbName) validationErrors.dbName = "El Campo es Obligatorio";
        if (engine === "mysql") {
            if (!dbHost) validationErrors.dbHost = "El Campo es Obligatorio";
            if (!dbPort) validationErrors.dbPort = "El Campo es Obligatorio";
            if (!dbUser) validationErrors.dbUser = "El Campo es Obligatorio";
            if (!dbPass) validationErrors.dbPass = "El Campo es Obligatorio";
        }
        return validationErrors;
    };

    const isHandleCloseSuccess = () => {
        setIsSuccessNotifVisible(false);
        navigate(-1);
    };

    const handleSave = async () => {
        const validationErrors = validateFields();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
        try {
            let response;
            setLoading(true);
            if (id) {
                const payloadUpdate = {
                    engine,
                    dbName,
                    dbPass,
                    dbHost,
                    dbPort,
                    dbUser,
                    flagActive: flagActive === 1
                };
                //response = await apiClient.patch(`/${numCont}/connectionToken/${companyId}/${id}`,
                response = await apiClient.patch(`/connectionToken/${id}`,
                    payloadUpdate
                );
                if (response.status === 200) {
                    //const register = response.data?.module + ' , ' + response.data?.companyRuc + ' , ' + response.data?.token;
                    const register = `Producto: ${response.data?.module?.toLowerCase()}
RUC: ${response.data?.companyRuc}
Token: ${response.data?.token}`;
                    setMensaje(register);
                    setIsSuccessVisible(true);
                    setIsSuccessNotifVisible(true);
                    setIsErrorVisible(false);
                    setIsLoginVisible(false);
                    setErrors({});
                    
                }
            } else {
                const payload = {
                    contractId,
                    companieId,
                    moduleId,
                    engine,
                    dbName,
                    dbPass,
                    dbHost,
                    dbPort,
                    dbUser,
                    flagActive: flagActive === 1
                };
                console.log(payload)
                // Al crear sin /${id}
                //response = await apiClient.post(`/${numCont}/connectionToken/${companyId}`, payload);
                response = await apiClient.post(`/connectionToken`, payload);
                if (response.status === 201) {
                    //const registerConn = response.data?.module + ' , ' + response.data?.companyRuc + ' , ' + response.data?.token;
                    const registerConn = `Producto: ${response.data?.module?.toLowerCase()}
RUC: ${response.data?.companyRuc}
Token: ${response.data?.token}`;
                    console.log(registerConn);

                    setMensaje(registerConn);
                    setIsSuccessVisible(true);
                    setIsSuccessNotifVisible(true);
                    setIsErrorVisible(false);
                    setErrors({});
                }
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                setIsLoginVisible(true);
            } else if (error.response?.data?.message.startsWith("CONNECTION_ALREADY_EXIST")) {
                setErrorMessage("Ya existe una conexión al módulo seleccionado.");
                setIsLoginVisible(false);
                setIsErrorVisible(true);
                setIsSuccessVisible(false);
                setIsSuccessNotifVisible(false);
                setTimeout(() => {
                    setIsErrorVisible(false);
                }, 4000);
            } else {
                setErrorMessage("Oops!. Ocurrio un error al crear la Conexión.");
                setIsLoginVisible(false);
                setIsErrorVisible(true);
                setIsSuccessVisible(false);
                setIsSuccessNotifVisible(false);
                setTimeout(() => {
                    setIsErrorVisible(false);
                }, 4000);
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="home-container">
            <Header onLogout={handleLogout} title="Conexiones Kpi" />
            <Section>
                <div className="button-return-container">
                    <FontAwesomeIcon
                        className="basic-shortcut-icon"
                        style={{ cursor: "pointer" }}
                        icon={faCircleArrowLeft}
                        onClick={(e) => {
                            e.stopPropagation();
                            navigate(-1);
                        }}
                    />
                </div>
            </Section>
            <div className="moduleNew-form-container">
                <h3 className="basic-info-form-title">Datos de Conexión KPI</h3>
                <div className="basic-info-form-grid">
                    <div className="basic-info-form-group">
                        <label style={{ color: errors.contractId ? "red" : "inherit" }}>ID de Contrato</label>
                        <input type="text"
                            placeholder="ID Contrato"
                            value={contractId}
                            onChange={(e) => setDbName(e.target.value)}
                            readOnly={!!id}/>
                        {errors.contractId && <p className="error-message">{errors.contractId}</p>}
                    </div>
                    <div className="basic-info-form-group">
                        <label style={{ color: errors.companieId ? "red" : "inherit" }}>ID de Companía</label>
                        <input type="text"
                            placeholder="ID Companía"
                            value={companieId}
                            onChange={(e) => setDbName(e.target.value)}
                            readOnly={!!id}/>
                        {errors.companieId && <p className="error-message">{errors.companieId}</p>}
                    </div>
                    
                    <div className="basic-info-form-group">
                        <label style={{ color: errors.moduleId ? "red" : "inherit" }}>Módulo</label>
                        <select
                            value={moduleId}
                            onChange={(e) => {
                                const selectedId = e.target.value; // ID del módulo seleccionado
                                setModuleId(selectedId); // Guardar el ID en moduleId
                            }
                        }
                        disabled={id !== undefined}
                        >
                            <option value="">Seleccione una opción</option>
                            {module.map((mod) => (
                                <option key={mod.id} value={mod.id}>
                                    {mod.modulo}
                                </option>
                            ))}
                        </select>
                        {errors.moduleId && <p className="error-message">{errors.moduleId}</p>}
                    </div>

                    <div className="basic-info-form-group">
                        <label style={{ color: errors.engine ? "red" : "inherit" }}>Tipo Conexión</label>
                        <select
                            value={engine}
                            onChange={(e) => setEngine(e.target.value)}
                        >
                            <option value="">Seleccione una opción</option>
                            <option value="mysql">MySQL</option>
                            <option value="vfp">VFoxPro</option>
                        </select>
                        {errors.engine && <p className="error-message">{errors.engine}</p>}
                    </div>

                    <div className="basic-info-form-group">
                        <label style={{ color: errors.dbName ? "red" : "inherit" }}>
                            Nombre de la Base de Datos
                        </label>
                        <input
                            type="text"
                            placeholder=" Nombre de la Base de Datos"
                            value={dbName}
                            onChange={(e) => setDbName(e.target.value)}

                        />
                        {errors.dbName && <p className="error-message">{errors.dbName}</p>}
                    </div>

                    {engine === "mysql" && (
                        <>
                            <div className="basic-info-form-group">
                                <label style={{ color: errors.dbHost ? "red" : "inherit" }}>
                                    Host
                                </label>
                                <input
                                    type="text"
                                    placeholder="Host"
                                    value={dbHost}
                                    onChange={(e) => setDbHost(e.target.value)}
                                />
                                {errors.dbHost && <p className="error-message">{errors.dbHost}</p>}
                            </div>
                            <div className="basic-info-form-group">
                                <label style={{ color: errors.dbPort ? "red" : "inherit" }}>
                                    Puerto
                                </label>
                                <input
                                    type="text"
                                    placeholder="Puerto"
                                    value={dbPort}
                                    onChange={(e) => setDbPort(e.target.value)}
                                />
                                {errors.dbPort && <p className="error-message">{errors.dbPort}</p>}
                            </div>
                            <div className="basic-info-form-group">
                                <label style={{ color: errors.dbUser ? "red" : "inherit" }}>
                                    Usuario
                                </label>
                                <input
                                    type="text"
                                    placeholder="Usuario"
                                    value={dbUser}
                                    onChange={(e) => setDbUser(e.target.value)}
                                />
                                {errors.dbUser && <p className="error-message">{errors.dbUser}</p>}
                            </div>
                            <div className="basic-info-form-group">
                                <label style={{ color: errors.dbPass ? "red" : "inherit" }}>
                                    Contraseña
                                </label>
                                <input
                                    type="text"
                                    placeholder="Contraseña"
                                    value={dbPass}
                                    onChange={(e) => setDbPass(e.target.value)}
                                />
                                {errors.dbPass && <p className="error-message">{errors.dbPass}</p>}
                            </div>
                        </>
                    )}
                    <div className="basic-info-form-group">
                        <div className="basic-info-form-switch">
                            <label>Estado</label>
                            <div className="slider-container-contract" onClick={toggleSwitch}>
                                <div
                                    className={`slider-option-contract ${flagActive === 1
                                        ? "active-contract"
                                        : "inactive-contract"
                                        }`}
                                >
                                    Activo
                                </div>
                                <div
                                    className={`slider-option-contract ${flagActive === 0
                                        ? "active-contract"
                                        : "inactive-contract"
                                        }`}
                                >
                                    Inactivo
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="basic-form-footer">
                    <button
                        className="basic-custom-button"
                        onClick={(e) => {
                            e.stopPropagation();
                            navigate(-1);
                        }}
                    >
                        <FontAwesomeIcon icon={faXmark} className="basic-shortcut-icon" />
                        Cancelar
                    </button>
                    <button
                        className="basic-custom-button"
                        onClick={handleSave}
                    >
                        <FontAwesomeIcon
                            icon={id ? faRotate : faSave}
                            className="basic-shortcut-icon"
                        />
                        {id ? "Actualizar" : "Guardar"}
                    </button>
                </div>
            </div>
            <SuccessNotification
                message={
                    id
                        ? `La Conexión KPI se ha Actualizado Correctamente.`
                        : `La Conexión KPI se ha Creado Correctamente.`
                }
                isVisible={isSuccessVisible}
                onClose={() => setIsSuccessVisible(false)}
            />
            <ErrorNotification
                message={errorsMessage}
                isVisible={isErrorVisible}
                onClose={() => setIsErrorVisible(false)}
            />
            <LoginNotification
                message="Vuelve a iniciar sesión"
                isVisible={isLoginVisible}
                onClose={() => setIsLoginVisible(false)}
                onLogout={handleLogout}
            />
            <NotificationConnectionKpi
                message={`Los datos de conexión son:`}
                men1={mensaje}  // Pasando men1 como prop
                isVisible={isSuccessNotifVisible}
                onClose={isHandleCloseSuccess}
                enca={id ? "Actualizado Correctamente" : false}
            />
            {loading && <Loader />}
        </div>
    );
};

export default ConnecctionKpiForm;
