import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import Section from "../../components/Section";
import SuccessNotification from "../../components/Notifications/SuccessNotification";
import ErrorNotification from "../../components/Notifications/ErrorNotification";
import LoginNotification from "../../components/Notifications/LoginNotification";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faSave,
    faRotate,
    faCircleArrowLeft,
    faXmark
} from "@fortawesome/free-solid-svg-icons";
import apiClient from "../../axios";

const CredentialsForm = ({ handleLogout }) => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [code, setCode] = useState("");
    const [project, setProject] = useState("");
    const [apiUrl, setApiUrl] = useState("");
    const [user, setUser] = useState("");
    const [password, setPassword] = useState("");
    const [token, setToken] = useState("");
    const [port, setPort] = useState(null);
    const [ddbase, setDdbase] = useState("");
    const [host, setHost] = useState("");
    const [idExternal, setIdExternal] = useState(null);
    const [security, setSecurity] = useState(1);
    const [isSuccessVisible, setIsSuccessVisible] = useState(false);
    const [isErrorVisible, setIsErrorVisible] = useState(false);
    const [errorsMessage, setErrorMessage] = useState({});
    const [isLoginVisible, setIsLoginVisible] = useState(false);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (id) {
            const fetchCRedential = async () => {
                try {
                    const response = await apiClient.get(`/admin/credentials/${id}`);
                    setCode(response.data.code);
                    setApiUrl(response.data.apiUrl);
                    setUser(response.data.user);
                    setToken(response.data.token);
                    setPort(response.data.port);
                    setDdbase(response.data.ddbase);
                    setHost(response.data.host);
                    setIdExternal(response.data.idExternal);
                    setSecurity(response.data.security);
                    setProject(response.data.project);

                } catch (error) {
                    console.error("Error al cargar la credencial", error);
                    setErrorMessage("Oops!. Ocurrio un error al cargar la Credencial");
                }
            };
            fetchCRedential();
        }
    }, [id]);

    const validateFields = () => {
        let validationErrors = {};
        const onlyLetters = /^[a-zA-Z]+$/;
        if (!code) {
            validationErrors.code = "El Campo es Obligatorio";
        } else if (!onlyLetters.test(code)) {
            validationErrors.code = "Solo se permiten letras";
        }
        if (!project) validationErrors.project = "El Campo es Obligatorio";
        return validationErrors;
    };

    const toggleSwitch = () => {
        setSecurity((prev) => !prev);
    };

    const handleSave = async () => {
        const validationErrors = validateFields();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
        try {
            let response;
            if (id) {
                const payloadUpdate = {
                    project,
                    apiUrl,
                    user,
                    password,
                    token,
                    port,
                    ddbase,
                    host,
                    idExternal,
                    security: security === false ? false : true
                };
                response = await apiClient.patch(
                    `/admin/credentials/${id}`,
                    payloadUpdate
                );
                if (response.status === 200) {
                    setIsSuccessVisible(true);
                    setIsErrorVisible(false);
                    setIsLoginVisible(false);
                    setErrors({});
                    setTimeout(() => {
                        setIsSuccessVisible(false);
                    }, 4000);
                    navigate("/CredentialsList");
                }
            } else {
                const payload = {
                    code,
                    project,
                    apiUrl,
                    user,
                    password,
                    token,
                    port,
                    ddbase,
                    host,
                    idExternal,
                    security: security === false ? false : true
                };
                response = await apiClient.post("/admin/credentials", payload);
                if (response.status === 201) {
                    setIsSuccessVisible(true);
                    setIsErrorVisible(false);
                    setErrors({});
                    setTimeout(() => {
                        setIsSuccessVisible(false);
                    }, 4000);
                    navigate("/CredentialsList");
                }
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                setIsLoginVisible(true);
            } else if (error.response?.data?.message.startsWith("CREDENTIAL_ALREADY_EXIST")) {
                setErrorMessage("El Permiso ya existe.");
                setIsLoginVisible(false);
                setIsErrorVisible(true);
                setIsSuccessVisible(false);
                setTimeout(() => {
                    setIsErrorVisible(false);
                }, 4000);
            }
            else {
                setErrorMessage("Oops!. Ocurrio un error al crear el Permiso");
                setIsLoginVisible(false);
                setIsErrorVisible(true);
                setIsSuccessVisible(false);
                setTimeout(() => {
                    setIsErrorVisible(false);
                }, 4000);
            }
        }
    };

    return (
        <div className="home-container">
            <Header onLogout={handleLogout} title="Credenciales" />
            <Section>
                <div className="button-return-container">
                    <FontAwesomeIcon
                        className="basic-shortcut-icon"
                        style={{ cursor: "pointer" }}
                        icon={faCircleArrowLeft}
                        onClick={(e) => {
                            e.stopPropagation();
                            navigate("/CredentialsList");
                        }}
                    />
                </div>
            </Section>
            <div className="moduleNew-form-container">
                <h3 className="basic-info-form-title">Información de la Credencial</h3>
                <div className="basic-info-form-grid">
                    <div className="basic-info-form-group">
                        <label style={{ color: errors.code ? "red" : "inherit" }}>
                            Código
                        </label>
                        <input
                            type="text"
                            placeholder="Código"
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                            readOnly={!!id}
                        />
                        {errors.code && <p className="error-message">{errors.code}</p>}
                    </div>
                    <div className="basic-info-form-group">
                        <label style={{ color: errors.project ? "red" : "inherit" }}>
                            Proyecto
                        </label>
                        <input
                            type="text"
                            placeholder="Proyecto"
                            value={project}
                            onChange={(e) => setProject(e.target.value)}
                        />
                        {errors.project && <p className="error-message">{errors.project}</p>}
                    </div>
                    <div className="basic-info-form-group">
                        <label>API URL</label>
                        <input
                            type="text"
                            placeholder="API URL"
                            value={apiUrl}
                            onChange={(e) => setApiUrl(e.target.value)}
                        />
                    </div>
                    <div className="basic-info-form-group">
                        <label>Usuario</label>
                        <input
                            type="text"
                            placeholder="Usuario"
                            value={user}
                            onChange={(e) => setUser(e.target.value)}
                        />
                    </div>
                    <div className="basic-info-form-group">
                        <label>Clave</label>
                        <input
                            type="text"
                            placeholder="Clave"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <div className="basic-info-form-group">
                        <label>Token</label>
                        <input
                            type="text"
                            placeholder="Token"
                            value={token}
                            onChange={(e) => setToken(e.target.value)}
                        />
                    </div>
                    <div className="basic-info-form-group">
                        <label>Puerto</label>
                        <input
                            type="text"
                            placeholder="Puerto"
                            value={port}
                            onChange={(e) => setPort(e.target.value)}
                        />
                    </div>
                    <div className="basic-info-form-group">
                        <label>Base de Datos</label>
                        <input
                            type="text"
                            placeholder="Base de Datos"
                            value={ddbase}
                            onChange={(e) => setDdbase(e.target.value)}
                        />
                    </div>
                    <div className="basic-info-form-group">
                        <label>Host</label>
                        <input
                            type="text"
                            placeholder="Host"
                            value={host}
                            onChange={(e) => setHost(e.target.value)}
                        />
                    </div>
                    <div className="basic-info-form-group">
                        <label>Id</label>
                        <input
                            type="text"
                            placeholder="Id"
                            value={idExternal}
                            onChange={(e) => setIdExternal(e.target.value)}
                        />
                    </div>
                    {id && (
                        <div className="basic-info-form-group4">
                            <label>Seguridad </label>
                            <div className="slider-container" onClick={toggleSwitch}>
                                <div className={`slider-option ${security ? "active" : "inactive"}`}>
                                    Activa
                                </div>
                                <div className={`slider-option ${!security ? "active" : "inactive"}`}>
                                    Inactiva
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="basic-form-footer">
                    <button
                        className="basic-custom-button"
                        onClick={(e) => {
                            e.stopPropagation();
                            navigate("/CredentialsList");
                        }}
                    >
                        <FontAwesomeIcon icon={faXmark} className="basic-shortcut-icon" />
                        Cancelar
                    </button>
                    <button
                        className="basic-custom-button"
                        onClick={handleSave}
                    >
                        <FontAwesomeIcon
                            icon={id ? faRotate : faSave}
                            className="basic-shortcut-icon"
                        />
                        {id ? "Actualizar" : "Guardar"}
                    </button>
                </div>
            </div>
            <SuccessNotification
                message={
                    id
                        ? "La Credencial se ha Actualizado Correctamente."
                        : "La Credencial se ha Creado Correctamente."
                }
                isVisible={isSuccessVisible}
                onClose={() => setIsSuccessVisible(false)}
            />
            <ErrorNotification
                message={errorsMessage}
                isVisible={isErrorVisible}
                onClose={() => setIsErrorVisible(false)}
            />
            <LoginNotification
                message="Vuele a iniciar sesión"
                isVisible={isLoginVisible}
                onClose={() => setIsLoginVisible(false)}
                onLogout={handleLogout}
            />

        </div>
    );
};

export default CredentialsForm;
