import React, { useState, useEffect, useCallback } from "react";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Header from "../../components/Header";
import Section from "../../components/Section";
import SuccessNotification from "../../components/Notifications/SuccessNotification";
import ErrorNotification from "../../components/Notifications/ErrorNotification";
import LoginNotification from "../../components/Notifications/LoginNotification";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faSave,
    faRotate,
    faCircleArrowLeft,
    faXmark
} from "@fortawesome/free-solid-svg-icons";
import apiClient from "../../axios";
import Loader from "../../components/Loader";

const CompaniesForm = ({ handleLogout }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = useParams();
    const { numCont } = location.state || {};
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [ruc, setRuc] = useState("");
    const [contract, setContract] = useState("");
    const [contractSearch, setContractSearch] = useState("");
    const [contractData, setContractData] = useState([]);
    const [isContractSelected, setIsContractSelected] = useState(false);
    const [sequence, setSequence] = useState("");
    const [isClosed, setIsClosed] = useState(false);
    const [isSuccessVisible, setIsSuccessVisible] = useState(false);
    const [isErrorVisible, setIsErrorVisible] = useState(false);
    const [errorsMessage, setErrorMessage] = useState({});
    const [isLoginVisible, setIsLoginVisible] = useState(false);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (id) {
            const fetchCompanie = async () => {
                try {
                    setLoading(true);
                    const response = await apiClient.get(`/admin/companies/${id}`);
                    setName(response.data.nombre);
                    setDescription(response.data.descripcion);
                    setRuc(response.data.ruc);
                    setSequence(response.data.sequence);
                    setContract(response.data.contrato);
                    setContractData(response.data.contrato);
                    setContractSearch(response.data.contrato);
                    setIsContractSelected(true);
                    setIsClosed(response.data.isClosed === 1 ? true : false || false);
                } catch (error) {
                    console.error("Error al cargar la Compania", error);
                } finally {
                    setLoading(false);
                }
            };
            fetchCompanie();
        }

    }, [id, numCont]);


    const toggleSwitch = () => {
        setIsClosed((prev) => !prev);
    };

    //BUSCAR CONTRATO
    const handleSearchContract = useCallback(async (page = 1) => {
        try {
            const params = new URLSearchParams();
            if (contractSearch) params.append('search', contractSearch);

            const response = await apiClient.get(`/contracts?${params.toString()}&page=${page}`);

            if (Array.isArray(response.data.results)) {
                setContractData(response.data.results);
            } else {
                setContractData([response.data.results]);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                setIsLoginVisible(true);
            }
        }
    }, [contractSearch]);

    useEffect(() => {
        if (!isContractSelected) {
            const delayDebounceFn = setTimeout(() => {
                if (contractSearch) {
                    handleSearchContract();
                }
            }, 200);

            return () => clearTimeout(delayDebounceFn);
        }
    }, [contractSearch, handleSearchContract, isContractSelected]);

    const handleInputChangeContract = (e) => {
        const value = e.target.value;
        setContractSearch(value);

        if (isContractSelected) {
            setIsContractSelected(false);
        }

        if (value.trim() === "") {
            setContractData([]);
        }
    };

    const handleContractSelect = (contractNumber, contractName) => {
        setContractSearch(contractName);
        setContract(contractNumber);
        setContractData([]);
        setIsContractSelected(true);
    };


    const validateFields = () => {
        let validationErrors = {};
        if (!name) validationErrors.name = "El Campo es Obligatorio";
        if (!description) validationErrors.description = "El Campo es Obligatorio";
        if (!ruc) validationErrors.ruc = "El Campo es Obligatorio";
        //if (numCont) if (!sequence) validationErrors.sequence = "El Campo es Obligatorio";
        return validationErrors;
    };

    const handleSave = async () => {
        const validationErrors = validateFields();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
        try {
            let response;
            setLoading(true);
            if (numCont) {
                if (id) {
                    const payloadUpdate = {
                        name,
                        description
                    };
                    response = await apiClient.patch(
                        `/admin/companies/${id}`,
                        payloadUpdate
                    );
                    if (response.status === 200) {
                        setIsSuccessVisible(true);
                        setIsErrorVisible(false);
                        setIsLoginVisible(false);
                        setErrors({});
                        setTimeout(() => {
                            setIsSuccessVisible(false);
                        }, 4000);
                        navigate(-1);
                    }
                } else {
                    const payload = {
                        name,
                        description,
                        ruc,
                        sequence
                    };
                    response = await apiClient.post(`/contracts/${numCont}/companies`, payload);
                    if (response.status === 201) {
                        setIsSuccessVisible(true);
                        setIsErrorVisible(false);
                        setErrors({});
                        setTimeout(() => {
                            setIsSuccessVisible(false);
                        }, 4000);
                        navigate(-1);
                    }
                }
            }
            else {
                if (id) {
                    const payloadUpdate = {
                        name,
                        description
                    };
                    response = await apiClient.patch(
                        `admin/companies/${id}`,
                        payloadUpdate
                    );
                    if (response.status === 200) {
                        setIsSuccessVisible(true);
                        setIsErrorVisible(false);
                        setIsLoginVisible(false);
                        setErrors({});
                        setTimeout(() => {
                            setIsSuccessVisible(false);
                        }, 4000);
                        navigate(-1);
                    }
                } else {
                    const payload = {
                        name,
                        description,
                        ruc,
                        contract,
                        isClosed
                    };
                    response = await apiClient.post(`/admin/companies`, payload);
                    if (response.status === 201) {
                        setIsSuccessVisible(true);
                        setIsErrorVisible(false);
                        setErrors({});
                        setTimeout(() => {
                            setIsSuccessVisible(false);
                        }, 4000);
                        navigate(-1);
                    }
                }
            }

        } catch (error) {
            if (error.response && error.response.status === 401) {
                setIsLoginVisible(true);
            } else if (error.response?.data?.message.startsWith("USERNAME_ALREADY_EXIST")) {
                setErrorMessage("La Compañía ya existe.");
                setIsLoginVisible(false);
                setIsErrorVisible(true);
                setIsSuccessVisible(false);
                setTimeout(() => {
                    setIsErrorVisible(false);
                }, 4000);
            } else if (error.response?.data?.message.startsWith("\"ruc\" length must be at least 13 characters long")) {
                setErrorMessage("El RUC ingresado debe tener 13 digitos.");
                setIsLoginVisible(false);
                setIsErrorVisible(true);
                setIsSuccessVisible(false);
                setTimeout(() => {
                    setIsErrorVisible(false);
                }, 4000);
            } else if (error.response?.data?.message.startsWith("\"ruc\" length must be less than or equal to 13 characters long")) {
                setErrorMessage("El RUC ingresado No puede tener más de 13 digitos.");
                setIsLoginVisible(false);
                setIsErrorVisible(true);
                setIsSuccessVisible(false);
                setTimeout(() => {
                    setIsErrorVisible(false);
                }, 4000);
            }
            else {
                setErrorMessage("Oops!. Ocurrio un error al crear la Compañía.");
                setIsLoginVisible(false);
                setIsErrorVisible(true);
                setIsSuccessVisible(false);
                setTimeout(() => {
                    setIsErrorVisible(false);
                }, 4000);
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="home-container">
            <Header onLogout={handleLogout} title="Compañías" />
            <Section>
                <div className="button-return-container">
                    <FontAwesomeIcon
                        className="basic-shortcut-icon"
                        style={{ cursor: "pointer" }}
                        icon={faCircleArrowLeft}
                        onClick={(e) => {
                            e.stopPropagation();
                            navigate(-1);
                        }}
                    />
                </div>
            </Section>
            <div className="moduleNew-form-container">
                <h3 className="basic-info-form-title">Información de la Compañía</h3>
                <div className="basic-info-form-grid">
                    {!numCont && (
                        <div className="basic-info-form-group">
                            <label style={{ color: errors.contract ? "red" : "inherit" }}>Nro. Contrato</label>
                            <input
                                type="text"
                                placeholder="Nro. Contrato"
                                value={contractSearch}
                                onChange={handleInputChangeContract}
                                readOnly={!!id}
                            />
                            {errors.contract && <p className="error-message">{errors.contract}</p>}

                            {contractData.length > 0 && (
                                <div className="search-results2">
                                    <ul>
                                        {contractData.map((item) => (
                                            <li key={item.id} onClick={() => handleContractSelect(item.numCont, item.numCont)}>
                                                {item.numCont}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </div>
                    )}
                    {numCont && (
                        <div className="basic-info-form-group">
                            <label >
                                Secuencia
                            </label>
                            <input
                                type="text"
                                placeholder="Secuencia"
                                value={sequence}
                                onChange={(e) => setSequence(e.target.value)}
                                readOnly={!!id}
                            />
                            
                        </div>
                    )}
                    <div className="basic-info-form-group">
                        <label style={{ color: errors.ruc ? "red" : "inherit" }}>
                            Ruc
                        </label>
                        <input
                            type="text"
                            placeholder="RUC"
                            value={ruc}
                            onChange={(e) => setRuc(e.target.value)}
                            readOnly={!!id}
                        />
                        {errors.ruc && <p className="error-message">{errors.ruc}</p>}
                    </div>
                    <div className="basic-info-form-group">
                        <label style={{ color: errors.name ? "red" : "inherit" }}>
                            Nombre
                        </label>
                        <input
                            type="text"
                            placeholder="Nombre"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        {errors.name && <p className="error-message">{errors.name}</p>}
                    </div>
                    <div className="basic-info-form-group">
                        <label style={{ color: errors.description ? "red" : "inherit" }}>
                            Descripción
                        </label>
                        <input
                            type="text"
                            placeholder="Descripción"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                        {errors.description && <p className="error-message">{errors.description}</p>}
                    </div>
                    {id && (
                        <div className="basic-info-form-group">
                            <div className="basic-info-form-switch">
                                <label>Período Cerrado</label>
                                <div className="slider-container-contract" onClick={toggleSwitch}>
                                    <div
                                        className={`slider-option-contract ${isClosed
                                            ? "active-contract"
                                            : "inactive-contract"
                                            }`}
                                    >
                                        Activo
                                    </div>
                                    <div
                                        className={`slider-option-contract ${!isClosed
                                            ? "active-contract"
                                            : "inactive-contract"
                                            }`}
                                    >
                                        Inactivo
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="basic-form-footer">
                    <button
                        className="basic-custom-button"
                        onClick={(e) => {
                            e.stopPropagation();
                            navigate(-1);
                        }}
                    >
                        <FontAwesomeIcon icon={faXmark} className="basic-shortcut-icon" />
                        Cancelar
                    </button>
                    <button
                        className="basic-custom-button"
                        onClick={handleSave}
                    >
                        <FontAwesomeIcon
                            icon={id ? faRotate : faSave}
                            className="basic-shortcut-icon"
                        />
                        {id ? "Actualizar" : "Guardar"}
                    </button>
                </div>
            </div>
            <SuccessNotification
                message={
                    id
                        ? "La Compañía se ha Actualizado Correctamente."
                        : "La Compañía se ha Creado Correctamente."
                }
                isVisible={isSuccessVisible}
                onClose={() => setIsSuccessVisible(false)}
            />
            <ErrorNotification
                message={errorsMessage}
                isVisible={isErrorVisible}
                onClose={() => setIsErrorVisible(false)}
            />
            <LoginNotification
                message="Vuele a iniciar sesión"
                isVisible={isLoginVisible}
                onClose={() => setIsLoginVisible(false)}
                onLogout={handleLogout}
            />
            {loading && <Loader />}
        </div>
    );
};

export default CompaniesForm;
