import React, { useState, useEffect } from "react";
import ErrorNotification from "../../components/Notifications/ErrorNotification";
import LoginNotification from "../../components/Notifications/LoginNotification";
import Loader from "../../components/Loader";
import apiClient from "../../axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";

const StatusModal = ({ message, isVisible, onClose, id }) => {
    const [loading, setLoading] = useState(false);
    const [isErrorVisible, setIsErrorVisible] = useState(false);
    const [errorsMessage, setErrorMessage] = useState({});
    const [isLoginVisible, setIsLoginVisible] = useState(false);
    const [engine, setEngine] = useState("");
    const [dbName, setDbName] = useState("");
    const [dbPass, setDbPass] = useState("");
    const [dbHost, setDbHost] = useState("");
    const [dbPort, setDbPort] = useState(0);
    const [dbUser, setDbUser] = useState("");
    const [flagActive, setCheckObservacion] = useState(0);

    useEffect(() => {
        const fetchConnection = async () => {
            try {
                setLoading(true);
                const response = await apiClient.get(`/connectionToken/${id}`);
                if (response.status === 200) {
                    setEngine(response.data.engine);
                    setDbName(response.data.dbName);
                    setDbPass(response.data.dbPass);
                    setDbHost(response.data.dbHost);
                    setDbPort(response.data.dbPort);
                    setDbUser(response.data.dbUser);
                    setCheckObservacion(response.data.flagActive ? 1 : 0);
                    console.log(response.data)
                }
            } catch (error) {
                console.error("Error al cargar la Conexión", error);
            } finally {
                setLoading(false);
            }
        };
        fetchConnection();
    }, [id]);
    
    const handleStatusChange = async () => {
        const newFlagActive = flagActive === 1 ? 0 : 1;
        setCheckObservacion(newFlagActive);

        const payloadUpdate = {
            engine,
            dbName,
            dbPass,
            dbHost,
            dbPort,
            dbUser,
            flagActive: newFlagActive === 1
        };
        try {
            setLoading(true);
            await apiClient.patch(`/connectionToken/${id}`, payloadUpdate);
            if (newFlagActive) {
                setTimeout(() => {
                    onClose();
                }, 500);
            } else {
                setTimeout(() => {
                    onClose();
                }, 500);
            }
        } catch (error) {
            if (error.response.data.error === "Unauthorized") {
                localStorage.clear();
                setErrorMessage('Ocurrió un error al actualizar la Conexión KPI');
                setIsErrorVisible(true);
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        isVisible && (
            <div className="info-notification-info-overlay">
                <div className="info-notification-info2" style={{ width: "400px" }}>
                    <div className="icon-container-noti-info">
                        <FontAwesomeIcon icon={faCircleInfo} className="icon-noti2" />
                    </div>
                    <div className="message-container-info">
                        <h3 className="basic-info-form-title">Estado</h3>
                        <p>{message}</p>
                    </div>
                    <div className="button-container-info">
                        <button className="cancel-btn-info" onClick={onClose}>
                            Cancelar
                        </button>
                        <button className="ok-btn-info" onClick={handleStatusChange}>
                            Aceptar
                        </button>
                    </div>
                    <ErrorNotification
                        message={errorsMessage}
                        isVisible={isErrorVisible}
                        onClose={() => setIsErrorVisible(false)}
                    />
                    <LoginNotification
                        message="Vuelve a iniciar sesión"
                        isVisible={isLoginVisible}
                        onClose={() => setIsLoginVisible(false)}
                    />
                    {loading && <Loader />}
                </div>
            </div>
        )
    );
};

export default StatusModal;
