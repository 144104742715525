import { useEffect, useCallback } from 'react';
const useEscapeKey = (onEscape, isEnabled = true) => {
  const handleKeyDown = useCallback(
    (event) => {
      if (isEnabled && event.key === 'Escape') {
        onEscape();
      }
    },
    [onEscape, isEnabled]
  );

  useEffect(() => {
    if (isEnabled) {
      window.addEventListener('keydown', handleKeyDown);
    }
    return () => {
      if (isEnabled) {
        window.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, [handleKeyDown, isEnabled]);
};

export default useEscapeKey;