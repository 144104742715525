import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import Section from "../../components/Section";
import './ContractReassignment.css';
import SuccessNotification from "../../components/Notifications/SuccessNotification";
import ErrorNotification from "../../components/Notifications/ErrorNotification";
import LoginNotification from "../../components/Notifications/LoginNotification";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faSave,
    faCircleArrowLeft,
    faXmark,
} from "@fortawesome/free-solid-svg-icons";
import apiClient from "../../axios";

const DistributorReassignment = ({ handleLogout }) => {
    const navigate = useNavigate();
    const [distributor, setDistributor] = useState("");
    const [contractSearch, setContractSearch] = useState("");
    const [contractData, setContractData] = useState([]);
    const [customer, setClient] = useState("");
    const [clientSearch, setClientSearch] = useState("");
    const [isSuccessVisible, setIsSuccessVisible] = useState(false);
    const [isErrorVisible, setIsErrorVisible] = useState(false);
    const [isInfoVisible, setIsInfoVisible] = useState(false);
    const [errors, setErrors] = useState({});
    const [searchTerm, setSearchTerm] = useState(''); // Para el valor de búsqueda
    const [data, setData] = useState([]);
    const [isContractSelected, setIsContractSelected] = useState(false);

    const validateFields = () => {
        let validationErrors = {};
        if (!distributor) validationErrors.distributor = "El Campo es Obligatorio";
        if (!customer) validationErrors.customer = "El Campo es Obligatorio";
        return validationErrors;
    };


    const handleClick = () => {
        navigate(-1);
    };

    //BUSCAR CLIENTE 
    const handleSearchCustomer = useCallback(async (page = 1) => {
        try {
            const params = new URLSearchParams();
            if (searchTerm) params.append('search', searchTerm);

            const response = await apiClient.get(`/clients?${params.toString()}&page=${page}`);

            if (Array.isArray(response.data.results)) {
                setData(response.data.results);
            } else {
                setData([response.data.results]);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                setIsInfoVisible(true);
            }
        }
    }, [searchTerm]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (searchTerm) {
                handleSearchCustomer();
            }
        }, 200);

        return () => clearTimeout(delayDebounceFn);
    }, [searchTerm, handleSearchCustomer]);

    const handleInputChange = (e) => {
        const value = e.target.value;
        setClientSearch(value);
        setSearchTerm(value);

        if (value.trim() === "") {
            setData([]);
        }
    };

    const handleClientSelect = (code, name) => {
        setClient(code);
        setClientSearch(name);
        setSearchTerm('');
        setData([]);
    };

    //BUSCAR DISTRIBUIDOR
    const handleSearchContract = useCallback(async (page = 1) => {
        try {
            const params = new URLSearchParams();
            if (contractSearch) params.append('search', contractSearch);

            const response = await apiClient.get(`/distributors?${params.toString()}&page=${page}`);

            if (Array.isArray(response.data.results)) {
                setContractData(response.data.results);
            } else {
                setContractData([response.data.results]);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                setIsInfoVisible(true);
            }
        }
    }, [contractSearch]);

    useEffect(() => {
        if (!isContractSelected) {
            const delayDebounceFn = setTimeout(() => {
                if (contractSearch) {
                    handleSearchContract();
                }
            }, 200);

            return () => clearTimeout(delayDebounceFn);
        }
    }, [contractSearch, handleSearchContract, isContractSelected]);

    const handleInputChangeContract = (e) => {
        const value = e.target.value;
        setContractSearch(value);

        if (isContractSelected) {
            setIsContractSelected(false);
        }

        if (value.trim() === "") {
            setContractData([]);
        }
    };

    const handleContractSelect = (code, name) => {
        setContractSearch(name);
        setDistributor(code);
        setContractData([]);
        setIsContractSelected(true);
    };


    const handleSave = async () => {
        const validationErrors = validateFields();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
        try {
            const payload = {
                distributor,
                customer
            };
            await apiClient.post("/admin/reassign_distributor", payload);
            setIsSuccessVisible(true);
            setIsErrorVisible(false);
            navigate(-1);
        } catch (error) {
            setIsErrorVisible(true);
            setIsSuccessVisible(false);
            setTimeout(() => {
                setIsErrorVisible(false);
            }, 4000);
            if (error.response && error.response.status === 401) {
                setIsInfoVisible(true);
            }
        }

    };

    return (
        <div className="home-container">
            <Header onLogout={handleLogout} title="Gestión de Clientes" />
            <Section>
                <div className="button-return-container">
                    <FontAwesomeIcon
                        className="basic-shortcut-icon"
                        style={{ cursor: "pointer" }}
                        icon={faCircleArrowLeft}
                        onClick={handleClick}
                    />
                </div>
            </Section>
            <div className="moduleNew-form-container">
                <h3 className="basic-info-form-title">Reasignación de Distribuidor</h3>
                <div className="basic-info-form-grid">

                    <div>
                        <div className="basic-info-form-group">
                            <label style={{ color: errors.customer ? "red" : "inherit" }}>Cliente</label>
                            <input
                                type="text"
                                placeholder="Cliente"
                                value={clientSearch}
                                onChange={handleInputChange}
                            />
                            {errors.customer && <p className="error-message">{errors.customer}</p>}

                        </div>
                        {data.length > 0 && (
                            <div className="search-results2">
                                <ul>
                                    {data.map((item) => (
                                        <li key={item.id} onClick={() => handleClientSelect(item.code, item.socialReason)}>
                                            {item.socialReason}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                    <div className="basic-info-form-group">
                        <label style={{ color: errors.distributor ? "red" : "inherit" }}>Distribuidor</label>
                        <input
                            type="text"
                            placeholder="Nro. Contrato"
                            value={contractSearch}
                            onChange={handleInputChangeContract}
                        />
                        {errors.distributor && <p className="error-message">{errors.distributor}</p>}

                        {contractData.length > 0 && (
                            <div className="search-results2">
                                <ul>
                                    {contractData.map((item) => (
                                        <li key={item.id} onClick={() => handleContractSelect(item.code, item.name)}>
                                            {item.name}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
                <div className="basic-form-footer">
                    <button
                        className="basic-custom-button"
                        onClick={(e) => {
                            e.stopPropagation();
                            navigate(-1);
                        }}
                    >
                        <FontAwesomeIcon icon={faXmark} className="basic-shortcut-icon" />
                        Cancelar
                    </button>
                    <button className="basic-custom-button" onClick={handleSave}>
                        <FontAwesomeIcon
                            icon={faSave}
                            className="basic-shortcut-icon"
                        />
                        Reasignar
                    </button>
                </div>
            </div>
            <SuccessNotification
                message={"El Contrato se ha Reasignado Correctamente."}
                isVisible={isSuccessVisible}
                onClose={() => setIsSuccessVisible(false)}
            />
            <ErrorNotification
                message="Hubo un problema al Reasignar el Contrato. Inténtalo de nuevo."
                isVisible={isErrorVisible}
                onClose={() => setIsErrorVisible(false)}
            />
            <LoginNotification
                message="Vuele a iniciar sesión"
                isVisible={isInfoVisible}
                onClose={() => setIsInfoVisible(false)}
                onLogout={handleLogout}
            />
        </div>
    );
};

export default DistributorReassignment;
