import React, { useState, useEffect } from "react";
import apiClient from "../../axios";
import SuccessProcess from '../Notifications/SuccessProcess';
import LoginNotification from "../Notifications/LoginNotification";
import ErrorNotification from '../Notifications/ErrorNotification';
import Loader from "../Loader";
import './AddUserCompany.css';

const AddUserCompany = ({ isVisible, onClose, element }) => {
    const [successVisible, setSuccessVisible] = useState(false);
    const [isInfoVisible, setIsInfoVisible] = useState(false);
    const [isErrorVisible, setIsErrorVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [assignedUsers, setAssignedUsers] = useState([]);
    const [searchAdd, setSearchAdd] = useState("");
    const [filteredUsersAdd, setFilteredUsersAdd] = useState([]);
    const [toAssign, setSelectedUsersAdd] = useState([]); // Usuarios seleccionados para agregar
    const [searchRemove, setSearchRemove] = useState("");
    const [filteredUsersRemove, setFilteredUsersRemove] = useState([]);
    const [toRevoke, setSelectedUsersRemove] = useState([]); // Usuarios seleccionados para quitar


    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await apiClient.get(`/users`);
                setUsers(response.data);
            } catch (error) {
                console.error("Error fetching users:", error);
            }
        };

        fetchUsers();
    }, []);

    useEffect(() => {
        const fetchAssignedUsers = async () => {
            try {
                const response = await apiClient.get(`/admin/companies/users/${element.id}`);
                setAssignedUsers(response.data);
            } catch (error) {
                console.error("Error fetching assigned users:", error);
            }
        };

        fetchAssignedUsers();
    }, [element]);

    useEffect(() => {
        const results = users.filter((user) =>
            user.userName.toLowerCase().includes(searchAdd.toLowerCase())
        );
        setFilteredUsersAdd(results);
    }, [searchAdd, users]);


    useEffect(() => {
        const results = users.filter((user) =>
            user.userName.toLowerCase().includes(searchRemove.toLowerCase())
        );
        setFilteredUsersRemove(results);
    }, [searchRemove, users]);

    const handleUserSelectAdd = (user) => {
        if (!toAssign.some((selected) => selected === user.userName)) {
            setSelectedUsersAdd([...toAssign, user.userName]);
            setSearchAdd("");
        }
    };
    const handleUserRemoveAdd = (userName) => {
        setSelectedUsersAdd(toAssign.filter((user) => user !== userName));
    };

    const handleUserSelectRemove = (user) => {
        if (!toRevoke.some((selected) => selected === user.userName)) {
            setSelectedUsersRemove([...toRevoke, user.userName]);
            setSearchRemove("");
        }
    };
    const handleUserRemoveRemove = (userName) => {
        setSelectedUsersRemove(toRevoke.filter((user) => user !== userName));
    };


    const handleConfirm = async () => {
        try {
            setLoading(true);

            const payload = {
                toAssign,
                toRevoke
            };
            const response = await apiClient.post(`/contracts/${element.contract}/companies/${element.id}/usersCompany`, payload);
            if (response.status === 200) {
                setSuccessVisible(true);
            }

        } catch (error) {
            setIsErrorVisible(true);
            if (error.response && error.response.status === 401) {
                setIsInfoVisible(true);
            }

        } finally {
            setLoading(false);
        }

    }

    const handleCloseSuccess = () => {
        setSuccessVisible(false);
        onClose();
    };

    return (
        isVisible && (
            <div className="info-notification-info-overlay">
                <div className="info-notification-info2">
                    <div className="message-container-info">
                        <h3 className="basic-info-form-title">Actualización de Usuarios de la compañía {element.id}</h3>
                    </div>
                    <div className="message-container-info">
                        <div className="basic-info-form-group-add">
                            <label>Usuario(s) Asignado(s)</label>
                            <div className="user-list">
                                {assignedUsers.length > 0 ? (
                                    assignedUsers.map(user => (
                                        <div key={user.id} className="user-chip">
                                            {user.userName}
                                        </div>
                                    ))
                                ) : (
                                    <p>No hay usuarios asignados</p>
                                )}
                            </div>
                        </div>
                        <div className="basic-info-form-group-add">
                            <label>Agregar Usuario(s)</label>
                            <div className="input-chip-container">
                                {toAssign.map((userName, index) => (
                                    <div key={index} className="chip">
                                        {userName}
                                        <span
                                            className="chip-close"
                                            onClick={() => handleUserRemoveAdd(userName)}
                                        >
                                            ×
                                        </span>
                                    </div>
                                ))}
                                <input
                                    type="text"
                                    placeholder="Buscar usuarios"
                                    value={searchAdd}
                                    onChange={(e) => setSearchAdd(e.target.value)}
                                />
                            </div>
                            {searchAdd && (
                                <div className="search-results2">
                                    <ul>
                                        {filteredUsersAdd.map((user) => (
                                            <li key={user.id} onClick={() => handleUserSelectAdd(user)}>
                                                {user.userName} - {user.description}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </div>
                        <div className="basic-info-form-group-add">
                            <label>Quitar Usuario(s)</label>
                            <div className="input-chip-container">
                                {toRevoke.map((userName, index) => (
                                    <div key={index} className="chip">
                                        {userName}
                                        <span
                                            className="chip-close"
                                            onClick={() => handleUserRemoveRemove(userName)}
                                        >
                                            ×
                                        </span>
                                    </div>
                                ))}
                                <input
                                    type="text"
                                    placeholder="Buscar usuarios"
                                    value={searchRemove}
                                    onChange={(e) => setSearchRemove(e.target.value)}
                                />
                            </div>
                            {searchRemove && (
                                <div className="search-results2">
                                    <ul>
                                        {filteredUsersRemove.map((user) => (
                                            <li key={user.id} onClick={() => handleUserSelectRemove(user)}>
                                                {user.userName} - {user.description}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </div>

                    </div>
                    <div className="button-container-info">
                        <button className="cancel-btn-info" onClick={onClose}>
                            Cancelar
                        </button>
                        <button className="ok-btn-info" onClick={handleConfirm}>
                            OK
                        </button>
                    </div>
                    {loading && <Loader />}
                    <SuccessProcess
                        message={"Usario(s) de la Compañia actualizado(s) exitosamente."}
                        isVisible={successVisible}
                        onClose={handleCloseSuccess}
                        redirectOnOk={false}
                    />
                    <ErrorNotification
                        message="Ups! Ocurrio un Problema"
                        isVisible={isErrorVisible}
                        onClose={() => setIsErrorVisible(false)}
                    />
                    <LoginNotification
                        message="Vuele a iniciar sesión"
                        isVisible={isInfoVisible}
                        onClose={() => setIsInfoVisible(false)}
                    />
                </div>
            </div>
        )
    );
};

export default AddUserCompany;
