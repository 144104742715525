import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import Section from "../../components/Section";
import SuccessNotification from "../../components/Notifications/SuccessNotification";
import ErrorNotification from "../../components/Notifications/ErrorNotification";
import LoginNotification from "../../components/Notifications/LoginNotification";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faSave,
    faRotate,
    faCircleArrowLeft,
    faXmark,
    faEyeSlash,
    faEye
} from "@fortawesome/free-solid-svg-icons";
import apiClient from "../../axios";

const TechnicianForm = ({ handleLogout }) => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [distributor, setDistributor] = useState([]);
    const [empresa_pc, setEmpresa] = useState("");
    const [tecnico_pc, setTecnico] = useState("");
    const [usuario, setUsuario] = useState("");
    const [clave, setPassword] = useState("");
    const [confirmarClave, setConfirmarClave] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const [email, setEmail] = useState("");
    const [isSuccessVisible, setIsSuccessVisible] = useState(false);
    const [isErrorVisible, setIsErrorVisible] = useState(false);
    const [errorsMessage, setErrorMessage] = useState({});
    const [isLoginVisible, setIsLoginVisible] = useState(false);
    const [errors, setErrors] = useState({});
    const [flag_active, setFlagActive] = useState(0);

    useEffect(() => {
        if (id) {
            const fetchProduct = async () => {
                try {
                    const response = await apiClient.get(`/technicians/${id}`);
                    setEmpresa(response.data.empresaPc);
                    setTecnico(response.data.tecnicoPc);
                    setUsuario(response.data.usuario);
                    setEmail(response.data.email);
                    setFlagActive(response.data.flagActive ? 1 : 0);
                   
                } catch (error) {
                    console.error("Error al cargar el Usuario", error);
                }
            };
            fetchProduct();
        }
        const fetchDistributors = async () => {
            try {
                const distr = await apiClient.get(`/distributors`);
                if (distr.status === 200) {
                    setDistributor(distr.data || []);
                }
            } catch (error) {
                console.error("Error al cargar los distribuidores", error);
            }
        };
        fetchDistributors();
    }, [id]);

    const toggleShowPassword = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    const toggleShowPassword2 = () => {
        setShowPassword2((prevShowPassword) => !prevShowPassword);
    };

    const toggleSwitch = () => {
        //setFlagActive((prev) => !prev);
        setFlagActive((prev) => (prev === 1 ? 0 : 1));
      };

    const validateEmail = (email) => {
        // Expresión regular para validar el formato de email
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email); // Solo valida el formato del correo
    };
    const handleChange = (e) => {
        const value = e.target.value;
        setEmail(value);

        // Validar el email
        if (!validateEmail(value)) {
            setErrors({
                email: 'Email inválido. Debe contener "@" y un dominio válido.',
            });
        } else {
            setErrors({});
        }
    };

    const validateFields = () => {
        let validationErrors = {};
        if (!empresa_pc) validationErrors.empresa_pc = "El Campo es Obligatorio";
        if (!tecnico_pc) validationErrors.tecnico_pc = "El Campo es Obligatorio";
        if (!usuario) validationErrors.usuario = "El Campo es Obligatorio";
        if (!email) validationErrors.email = "El Campo es Obligatorio";
        if (!clave) validationErrors.clave = "El Campo es Obligatorio";
        if (clave !== confirmarClave) {
            validationErrors.confirmarClave = "Las claves no coinciden";
        }
        return validationErrors;
    };

    const handleSave = async () => {
        const validationErrors = validateFields();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
        try {
            let response;
            if (id) {
                const payloadUpdate = {
                    empresa_pc,
                    tecnico_pc,
                    clave,
                    email,
                    flag_active: flag_active === 1
                };
                response = await apiClient.patch(`/technicians/${id}`, payloadUpdate
                );
                if (response.status === 200) {
                    setIsSuccessVisible(true);
                    setIsErrorVisible(false);
                    setIsLoginVisible(false);
                    setErrors({});
                    setTimeout(() => {
                        setIsSuccessVisible(false);
                    }, 4000);
                    navigate("/TechnicianList");
                }
            } else {
                const payload = {
                    empresa_pc,
                    tecnico_pc,
                    usuario,
                    clave,
                    email,
                    flag_active: flag_active === 1
                };
                response = await apiClient.post("/technicians", payload);
                if (response.status === 201) {
                    setIsSuccessVisible(true);
                    setIsErrorVisible(false);
                    setErrors({});
                    setTimeout(() => {
                        setIsSuccessVisible(false);
                    }, 4000);
                    navigate("/TechnicianList");
                }
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                setIsLoginVisible(true);
            } else if (error.response?.data?.message.startsWith("USER_ALREADY_IN_USE")) {
                setErrorMessage("El Usuario ingresado ya tiene un Equipo Técnico asociado.");
                setIsLoginVisible(false);
                setIsErrorVisible(true);
                setIsSuccessVisible(false);
                setTimeout(() => {
                    setIsErrorVisible(false);
                }, 4000);
            }
            else {
                setErrorMessage("Oops!. Ocurrió un error al crear el Equipo Técnico");
                setIsLoginVisible(false);
                setIsErrorVisible(true);
                setIsSuccessVisible(false);
                setTimeout(() => {
                    setIsErrorVisible(false);
                }, 4000);
            }
        }
    };

    return (
        <div className="home-container">
            <Header onLogout={handleLogout} title="Equipo Técnico" />
            <Section>
                <div className="button-return-container">
                    <FontAwesomeIcon
                        className="basic-shortcut-icon"
                        style={{ cursor: "pointer" }}
                        icon={faCircleArrowLeft}
                        onClick={(e) => {
                            e.stopPropagation();
                            navigate("/TechnicianList");
                        }}
                    />
                </div>
            </Section>
            <div className="moduleNew-form-container">
                <h3 className="basic-info-form-title">Información del Equipo Técnico</h3>
                <div className="basic-info-form-grid">
                    <div className="basic-info-form-group">
                        <label style={{ color: errors.empresa_pc ? "red" : "inherit" }}>
                            Nombre de Empresa </label>
                        <select
                            value={empresa_pc}
                            onChange={(e) => {
                                const selectedId = e.target.value;
                                setEmpresa(selectedId);
                            }
                        } disabled={id !== undefined} >
                            <option value="">Seleccione una opción</option>
                            {distributor.map((emp) => (
                                <option key={emp.id} value={emp.id}>
                                    {emp.name}
                                </option>
                            ))}
                        </select>
                        {errors.empresa_pc && <p className="error-message">{errors.empresa_pc}</p>}
                    </div>
                    <div className="basic-info-form-group">
                        <label style={{ color: errors.tecnico_pc ? "red" : "inherit" }}>
                            Nombre del Técnico
                        </label>
                        <input
                            type="text"
                            placeholder=" Nombre del Técnico"
                            value={tecnico_pc}
                            onChange={(e) => setTecnico(e.target.value)}

                        />
                        {errors.tecnico_pc && <p className="error-message">{errors.tecnico_pc}</p>}
                    </div>
                    <div className="basic-info-form-group">
                        <label style={{ color: errors.usuario ? "red" : "inherit" }}>
                            Usuario
                        </label>
                        <input
                            type="text"
                            placeholder="Usuario"
                            value={usuario}
                            onChange={(e) => setUsuario(e.target.value)}
                            readOnly={!!id}

                        />
                        {errors.usuario && <p className="error-message">{errors.usuario}</p>}
                    </div>
                    <div className="basic-info-form-group">
                        <label style={{ color: errors.clave ? "red" : "inherit" }}>
                            Clave
                        </label>
                        <div className="password-input-container">
                            <input
                                className="text-box-login2"
                                type={showPassword ? "text" : "password"}
                                value={clave}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Contraseña"
                            />
                            <FontAwesomeIcon
                                icon={showPassword ? faEyeSlash : faEye}
                                className="password-toggle-icon2"
                                onClick={toggleShowPassword}
                            />
                        </div>
                        {errors.clave && <p className="error-message">{errors.clave}</p>}
                    </div>
                    <div className="basic-info-form-group">
                        <label style={{ color: errors.confirmarClave ? "red" : "inherit" }}>
                            Confirmar Clave
                        </label>
                        <div className="password-input-container">
                            <input
                                className="text-box-login2"
                                type={showPassword2 ? "text" : "password"}
                                placeholder="Confirmar Clave"
                                value={confirmarClave}
                                onChange={(e) => setConfirmarClave(e.target.value)}
                            />
                            <FontAwesomeIcon
                                icon={showPassword2 ? faEyeSlash : faEye}
                                className="password-toggle-icon2"
                                onClick={toggleShowPassword2}
                            />
                        </div>
                        {errors.confirmarClave && <p className="error-message">{errors.confirmarClave}</p>}
                    </div>
                    <div className="basic-info-form-group">
                        <label style={{ color: errors.email ? "red" : "inherit" }}>
                            {" "}
                            Email
                        </label>
                        <input
                            type="text"
                            placeholder="Email"
                            value={email}
                            onChange={handleChange}
                        />
                        {errors.email && <p className="error-message">{errors.email}</p>}
                    </div>
                    <div className="basic-info-form-group">
                        <div className="basic-info-form-switch">
                            <label>Estado</label>
                            <div className="slider-container-contract" onClick={toggleSwitch}>
                                <div
                                    className={`slider-option-contract ${flag_active === 1
                                        ? "active-contract"
                                        : "inactive-contract"
                                        }`}
                                >
                                    Activo
                                </div>
                                <div
                                    className={`slider-option-contract ${flag_active === 0
                                        ? "active-contract"
                                        : "inactive-contract"
                                        }`}
                                >
                                    Inactivo
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="basic-form-footer">
                    <button
                        className="basic-custom-button"
                        onClick={(e) => {
                            e.stopPropagation();
                            navigate("/TechnicianList");
                        }}
                    >
                        <FontAwesomeIcon icon={faXmark} className="basic-shortcut-icon" />
                        Cancelar
                    </button>
                    <button
                        className="basic-custom-button"
                        onClick={handleSave}
                    >
                        <FontAwesomeIcon
                            icon={id ? faRotate : faSave}
                            className="basic-shortcut-icon"
                        />
                        {id ? "Actualizar" : "Guardar"}
                    </button>
                </div>
            </div>
            <SuccessNotification
                message={
                    id
                        ? "El Equipo Técnico se ha Actualizado Correctamente."
                        : "El Equipo Técnico se ha Creado Correctamente."
                }
                isVisible={isSuccessVisible}
                onClose={() => setIsSuccessVisible(false)}
            />
            <ErrorNotification
                message={errorsMessage}
                isVisible={isErrorVisible}
                onClose={() => setIsErrorVisible(false)}
            />
            <LoginNotification
                message="Vuele a iniciar sesión"
                isVisible={isLoginVisible}
                onClose={() => setIsLoginVisible(false)}
                onLogout={handleLogout}
            />

        </div>
    );
};

export default TechnicianForm;
