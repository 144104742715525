import React, { useState } from 'react';
// import { useLocation, useNavigate } from "react-router-dom";
import imgAcl from '../../../src/static/ACL-Color.png';
import apiClient from "../../axios";
import SuccessProcess from '../Notifications/SuccessProcess';
import LoginNotification from "../Notifications/LoginNotification";

const LoginModal = ({ isVisible, onClose, modulo }) => {
    const [successVisible, setSuccessVisible] = useState(false);
    const [isInfoVisible, setIsInfoVisible] = useState(false);
    const [message2, setMessage2] = useState('');
    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState({});


    const validateEmail = (email) => {
        // Expresión regular para validar el formato de email
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email); // Solo valida el formato del correo
    };

    const handleChange = (e) => {
        const value = e.target.value;
        setEmail(value);

        // Validar el email
        if (!validateEmail(value)) {
            setErrors({
                email: 'Email inválido. Debe contener "@" y un dominio válido.',
            });
        } else {
            setErrors({});
        }
    };

    const validateFields = () => {
        let validationErrors = {};
        if (!email) validationErrors.email = "El Campo es Obligatorio";
        return validationErrors;
    };


    const handleConfirm = async () => {

        const validationErrors = validateFields();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        const payload = {
            email
        };
        try {
            const response = await apiClient.post(`/forgot_password`, payload);
            if (response.status === 202) {
                setSuccessVisible(true);
                setMessage2('Hemos enviado un enlace para restablecer tu contraseña al correo electrónico que ingresaste.');

            }

        } catch (error) {
            setMessage2('Ocurrió un error al actualizar el módulo.');
            if (error.response.data.error === "Unauthorized") {
                setIsInfoVisible(true);
            }
        }

    };

    const handleCloseSuccess = () => {
        setSuccessVisible(false);
        onClose();
    };

    return (
        isVisible && (
            <div className="info-notification-info-overlay">
                <div className="info-notification-info3" >
                    <div className="icon-container-noti-info">
                        <div className="logo-fenix">
                            <img src={imgAcl} alt="Logo" />
                        </div>
                    </div>
                    <div className="message-container-info">

                        <h3 className="modal-title ">Recuperación de Contraseña</h3>
                    </div>
                    <div className="message-container-info">
                        <div className="basic-info-form-group">
                            <label style={{ color: errors.email ? "red" : "inherit" }}>
                                Correo Electrónico
                            </label>
                            <input
                                type="text"
                                placeholder="Correo Electrónico"
                                value={email}
                                onChange={handleChange}
                            />
                            {errors.email && (
                                <p style={{ fontSize: '12px', color: 'red' }}>
                                    {errors.email}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="button-container-info">
                        <button className="cancel-btn-info" onClick={onClose}>
                            Cancelar
                        </button>
                        <button className="ok-btn-info" onClick={handleConfirm}>
                            OK
                        </button>
                    </div>
                    <SuccessProcess
                        enca="Información"
                        message={message2}
                        isVisible={successVisible}
                        onClose={handleCloseSuccess}
                        redirectOnOk={false}
                    />
                    <LoginNotification
                        message="Vuele a iniciar sesión"
                        isVisible={isInfoVisible}
                        onClose={() => setIsInfoVisible(false)}
                    />
                </div>
            </div>
        )
    );
};

export default LoginModal;
