import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import Section from "../../components/Section";
import SuccessNotification from "../../components/Notifications/SuccessNotification";
import ErrorNotification from "../../components/Notifications/ErrorNotification";
import LoginNotification from "../../components/Notifications/LoginNotification";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faSave,
    faRotate,
    faCircleArrowLeft,
    faXmark
} from "@fortawesome/free-solid-svg-icons";
import apiClient from "../../axios";

const ParametersForm = ({ handleLogout }) => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [key, setKey] = useState("");
    const [value, setValue] = useState("");
    const [type, setType] = useState("");
    const [isSuccessVisible, setIsSuccessVisible] = useState(false);
    const [isErrorVisible, setIsErrorVisible] = useState(false);
    const [errorsMessage, setErrorMessage] = useState({});
    const [isLoginVisible, setIsLoginVisible] = useState(false);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (id) {
            const fetchParameter = async () => {
                try {
                    const response = await apiClient.get(`/admin/parameters/${id}`);
                    setKey(response.data.key);
                    setValue(response.data.value);
                    setType(response.data.type);
                } catch (error) {
                    console.error("Error al cargar el Parámetro", error);
                    setErrorMessage("Oops!. Ocurrio un error al cargar el Parámetro");
                }
            };
            fetchParameter();
        }
    }, [id]);

    const validateFields = () => {
        let validationErrors = {};
        const onlyLetters = /^[a-zA-Z]+$/;
        if (!key) {
            validationErrors.key = "El Campo es Obligatorio";
        } else if (!onlyLetters.test(key)) {
            validationErrors.key = "Solo se permiten letras";
        }
        if (!value) validationErrors.value = "El Campo es Obligatorio";
        if (!type) validationErrors.type = "El Campo es Obligatorio";
        return validationErrors;
    };


    const handleSave = async () => {
        const validationErrors = validateFields();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
        try {
            let response;
            if (id) {
                const payloadUpdate = {
                    value,
                    type
                };
                response = await apiClient.patch(
                    `/admin/parameters/${id}`,
                    payloadUpdate
                );
                if (response.status === 200) {
                    setIsSuccessVisible(true);
                    setIsErrorVisible(false);
                    setIsLoginVisible(false);
                    setErrors({});
                    setTimeout(() => {
                        setIsSuccessVisible(false);
                    }, 4000);
                    navigate("/ParametersList");
                }
            } else {
                const payload = {
                    key,
                    value,
                    type
                };
                response = await apiClient.post("/admin/parameters", payload);
                if (response.status === 201) {
                    setIsSuccessVisible(true);
                    setIsErrorVisible(false);
                    setErrors({});
                    setTimeout(() => {
                        setIsSuccessVisible(false);
                    }, 4000);
                    navigate("/ParametersList");
                }
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                setIsLoginVisible(true);
            } else if (error.response?.data?.message.startsWith("PARAMETER_ALREADY_EXIST")) {
                setErrorMessage("El Parámetro ya existe.");
                setIsLoginVisible(false);
                setIsErrorVisible(true);
                setIsSuccessVisible(false);
                setTimeout(() => {
                    setIsErrorVisible(false);
                }, 4000);
            }
            else {
                setErrorMessage("Oops!. Ocurrio un error al crear el Parámetro");
                setIsLoginVisible(false);
                setIsErrorVisible(true);
                setIsSuccessVisible(false);
                setTimeout(() => {
                    setIsErrorVisible(false);
                }, 4000);
            }
        }
    };

    return (
        <div className="home-container">
            <Header onLogout={handleLogout} title="Parámetros" />
            <Section>
                <div className="button-return-container">
                    <FontAwesomeIcon
                        className="basic-shortcut-icon"
                        style={{ cursor: "pointer" }}
                        icon={faCircleArrowLeft}
                        onClick={(e) => {
                            e.stopPropagation();
                            navigate("/ParametersList");
                        }}
                    />
                </div>
            </Section>
            <div className="moduleNew-form-container">
                <h3 className="basic-info-form-title">Información del Parámetro</h3>
                <div className="basic-info-form-grid">
                    <div className="basic-info-form-group">
                        <label style={{ color: errors.key ? "red" : "inherit" }}>
                            Código
                        </label>
                        <input
                            type="text"
                            placeholder="Código"
                            value={key}
                            onChange={(e) => setKey(e.target.value)}
                            readOnly={!!id}
                        />
                        {errors.key && <p className="error-message">{errors.key}</p>}
                    </div>
                    <div className="basic-info-form-group">
                        <label style={{ color: errors.value ? "red" : "inherit" }}>
                            Valor
                        </label>
                        <input
                            type="text"
                            placeholder="Valor"
                            value={value}
                            onChange={(e) => setValue(e.target.value)}
                        />
                        {errors.value && <p className="error-message">{errors.value}</p>}
                    </div>
                    <div className="basic-info-form-group">
                        <label style={{ color: errors.type ? "red" : "inherit" }}>
                            Tipo
                        </label>
                        <input
                            type="text"
                            placeholder="Tipo"
                            value={type}
                            onChange={(e) => setType(e.target.value)}
                        />
                        {errors.type && <p className="error-message">{errors.type}</p>}
                    </div>
                </div>
                <div className="basic-form-footer">
                    <button
                        className="basic-custom-button"
                        onClick={(e) => {
                            e.stopPropagation();
                            navigate("/ParametersList");
                        }}
                    >
                        <FontAwesomeIcon icon={faXmark} className="basic-shortcut-icon" />
                        Cancelar
                    </button>
                    <button
                        className="basic-custom-button"
                        onClick={handleSave}
                    >
                        <FontAwesomeIcon
                            icon={id ? faRotate : faSave}
                            className="basic-shortcut-icon"
                        />
                        {id ? "Actualizar" : "Guardar"}
                    </button>
                </div>
            </div>
            <SuccessNotification
                message={
                    id
                        ? "El Parámetro se ha Actualizado Correctamente."
                        : "EL Parámetro se ha Creado Correctamente."
                }
                isVisible={isSuccessVisible}
                onClose={() => setIsSuccessVisible(false)}
            />
            <ErrorNotification
                message={errorsMessage}
                isVisible={isErrorVisible}
                onClose={() => setIsErrorVisible(false)}
            />
            <LoginNotification
                message="Vuele a iniciar sesión"
                isVisible={isLoginVisible}
                onClose={() => setIsLoginVisible(false)}
                onLogout={handleLogout}
            />

        </div>
    );
};

export default ParametersForm;
