import React, { useState, useEffect, useCallback , useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./ModulesForm.css";
import ModalStatus from "../../components/Notifications/ModuleStatus";
import WarningNotification from "../../components/Notifications/WarningNotification";
import Section from "../../components/Section";
import { faThLarge, faEye, faSearchPlus, faRotate, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Header, Table } from "../../components";
import SuccessNotification from '../../components/Notifications/SuccessNotification';
import ErrorNotification from '../../components/Notifications/ErrorNotification';
import LoginNotification from "../../components/Notifications/LoginNotification";
import SlideModal from '../../components/SlideModal';
import DeleteModal from '../../components/DeleteModal';
import {
  faEdit,
  faTrashAlt,
  faCircleCheck,
  faCircleXmark,
  faCircleArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "react-tooltip";
import apiClient from "../../axios";
import AddModulesModal from "../../components/AddModulesModal";
import AddComboModal from "../../components/AddComboModal";
import AddSubscriptionsModal from "../../components/AddSubscriptionsModal";

const ModulesForm = ({ handleLogout }) => {
  const location = useLocation();
  const { modules } = location.state || {};
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRow, setSelectedRow] = useState(null);
  const [search, setSearch] = useState(null); // Estado definitivo para la búsqueda
  const [customer, setCustomer] = useState(null);
  const navigate = useNavigate();
  const [data, setData] = useState(modules.modules || []); // Estado inicial con los datos
  const [dataInfo] = useState(modules || []);
  const [isSuccessVisible, setIsSuccessVisible] = useState(false);
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [isWarningVisible, setIsWarningVisible] = useState(false);
  const [isInfoVisible, setIsInfoVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tempSearch, setTempSearch] = useState(''); // Temporal para el input de contrato
  const [tempCustomers, setTempCustomers] = useState(''); // Temporal para el input de cliente
  const role = localStorage.getItem('role');
  const [isModalModulesOpen, setIsModalModulesOpen] = useState(false);
  const [isModalComboOpen, setIsModalComboOpen] = useState(false);
  const [isModalSuscriptionOpen, setIsModalSuscriptionOpen] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null); 
  const [deleteItem, setDeleteItem] = useState(false);
  const [selectedDeleteItem, setSelectedDeleteItem] = useState(null);

  const hasPermission = (permission) => {
    const permissions = JSON.parse(localStorage.getItem('permissions')) || [];
    return permissions.includes(permission);
  };

  const toggleDropdown = () => setShowDropdown(!showDropdown);

  const handleOptionClick = (action) => {
    setShowDropdown(false);
    action();
  };

  // Cierra el menú si se hace clic fuera de él
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleStatusClick = (item) => {
    if (item.activo && hasPermission('MODULE_BLOCK')) {
      setSelectedItem(item);
      setModalVisible(true);
    } else if (!item.activo && hasPermission('MODULE_UNBLOCK')) {
      setSelectedItem(item);
      setModalVisible(true);
    } else {
      setIsWarningVisible(true);
      setTimeout(() => {
        setIsWarningVisible(false);
      }, 4000);
    }
  };

  const openModal = () => {
    setIsModalModulesOpen(true);
  };
  const closeModal = () => {
    setIsModalModulesOpen(false);
  };

  const openComboModal = () => {
    setIsModalComboOpen(true);
  };
  const closeCmoboModal = () => {
    setIsModalComboOpen(false);
  };

  const openSubscriptionModal = () => {
    setIsModalSuscriptionOpen(true);
  };
  const closeSubscriptionModal = () => {
    setIsModalSuscriptionOpen(false);
  };

  const toggleModal = async (val, item) => {
    setSelectedItem(item);
    setIsModalOpen(val);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
    setSelectedItem(null);
    onRefreshThis()
  };

  const handleClick = () => {
    navigate(-1);
  };
  const assignSearchValues = () => {
    if (tempSearch) {
      setSearch(tempSearch);
      setCustomer(''); // Limpia cliente si hay algo en contrato
    } else if (tempCustomers) {
      setCustomer(tempCustomers);
      setSearch(''); // Limpia contrato si hay algo en cliente
    } else {
      setCustomer('');
      setSearch('');
      handleSearch();
    }
  };

  const handleSearch = useCallback(async (page = 1) => {
    try {
      setLoading(true)
      setIsSuccessVisible(true);
      let endPoint = `contracts/${modules.numCont}/modules?page=${page}`;
      if (customer) {
        endPoint += `&module=${customer}`;
      } else if (search) {
        endPoint += `&search=${search}`;
      }
      const response = await apiClient.get(endPoint);
      if (Array.isArray(response.data.results)) {
        setData(response.data.results);
      } else {
        setData([response.data.results]);
      }
      setTotalItems(response.data.total || 1);
      setLoading(false)
    } catch (error) {
      setIsErrorVisible(true);
      setLoading(false)
      if (error.response.data.error === "Unauthorized") {
        setIsInfoVisible(true);
      }
    }
  }, [modules.numCont, customer, search]);

  useEffect(() => {
    handleSearch(currentPage); // Cargar datos al montar el componente
  }, [handleSearch, currentPage]);

  const onRefreshThis = () => {
    setTempSearch('');
    setCustomer('');
    setSearch('');
    setTempCustomers('');
    handleSearch(1)
  };

  const handleDeleteModal = (item) => {
    setSelectedDeleteItem(item);
    setDeleteItem(true);
  }
  const handleCloseDeleteModal = () => {
    setDeleteItem(false);
    setSelectedDeleteItem(null);
    onRefreshThis()
  };

  const handleSync = useCallback(async () => {
    try {
      setLoading(true);
      const url = `/contracts/${modules.numCont}/modules/sync`;
      const response = await apiClient.post(url);
      if (response.status === 200) {
        setIsSuccessVisible(true);
        handleSearch();
      }
    } catch (error) {
      if (error.response.data.error === "Unauthorized") {
        setIsInfoVisible(true);
      }
    } finally {
      setLoading(false);
    }
  }, [modules.numCont, handleSearch]);

  const columns = [
    { title: "Descripción", key: "descripcion" },
    { title: "Módulo", key: "modulo" },
    { title: "Fecha Caducidad", key: "expire" },
    { title: "Plan", key: "modulo" },
    { title: "Nro. Procesos", key: "procesos" },
    { title: "Contrato", key: "numContId" },
    { title: "Estado", key: "acciones" },
    { title: "Acciones", key: "acciones" },
  ];

  const itemsPerPage = 10;

  const renderRow = (item, index) => (
    <>
      <td>{item?.descripcion}</td>
      <td>{item?.modulo}</td>
      <td>{new Date(item.fechaFin).toLocaleString()}</td>
      <td>{item?.isPay ? 'Pagado' : 'Gratis'}</td>
      <td>{item?.maxCount}</td>
      <td>{item?.numContId}</td>
      <td>
        {item.activo ? (
          <button
            className="status_button"
            onClick={() => handleStatusClick(item)}
          >
            <FontAwesomeIcon
              icon={faCircleCheck}
              style={{ color: "green", fontSize: "24px" }}
            />
          </button>
        ) : (
          <button
            className="status_button"
            onClick={() => handleStatusClick(item)}
          >
            <FontAwesomeIcon
              className="status_icon"
              icon={faCircleXmark}
              style={{ color: "red", fontSize: "24px" }}
            />
          </button>
        )}
      </td>
      <td>
        <div className="button-container">
          <Tooltip id="edit-tooltip" className="custom-tooltip" />
          {hasPermission('MODULE_UPDATE') && (
            <button
              data-tooltip-id="edit-tooltip"
              className="icon-button edit-button"
              data-tooltip-content="Editar"
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/ModuleContract?moduleId=${item.id}`);
              }}
            >
              <FontAwesomeIcon icon={faEdit} />
            </button>
          )}
          <Tooltip id="delete-tooltip" className="custom-tooltip" />
          {hasPermission('MODULE_DELETE') && (
            <button
              data-tooltip-id="delete-tooltip"
              data-tooltip-content="Eliminar"
              className="icon-button delete-button"
              onClick={() => handleDeleteModal(item)}
              //onClick={() => handleDelete(item.id)}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </button>
          )}
          {hasPermission('MODULE_VIEW') && (
            <button
              data-tooltip-id="edit-tooltip"
              className="icon-button edit-button"
              data-tooltip-content="Detalles"
              onClick={(e) => {
                e.stopPropagation(); // Evita que el clic en el botón se propague al td
                toggleModal(true, item)
              }}
            >
              <FontAwesomeIcon icon={faEye} />
            </button>
          )}
        </div>
      </td>
    </>
  );

  const handleRowClick = (item) => {
    setSelectedRow(item);
  };

  return (
    <div className="home-container">
      <Header onLogout={handleLogout} title="Módulos por Contrato" />
      <div className="home-content">
        <Section>
          <div className="button-return-container">
            <div className="contener-return">
              <FontAwesomeIcon
                className="basic-shortcut-icon"
                style={{ cursor: "pointer" }}
                icon={faCircleArrowLeft}
                onClick={handleClick}
              />
            </div>
            <div className="filter-form">
              <div className="form-group-contract ">
                <input
                  className="contract-input"
                  type="text"
                  id="search"
                  value={tempSearch}
                  onChange={(e) => {
                    setTempSearch(e.target.value);
                    setTempCustomers(''); // Limpia cliente cuando escribes en contrato
                  }}
                  placeholder="Descripción"
                />
              </div>
              <div className="form-group-contract ">
                <input
                  className="contract-input"
                  type="text"
                  id="customers"
                  value={tempCustomers}
                  onChange={(e) => {
                    setTempCustomers(e.target.value);
                    setTempSearch(''); // Limpia contrato cuando escribes en cliente
                  }}
                  placeholder="Módulo"
                />
              </div>
            </div>
            <div className="button-add">
              <button
                className="basic-custom-button"
                onClick={() => assignSearchValues()}
              >
                <FontAwesomeIcon className="basic-shortcut-icon" icon={faSearchPlus} />
                Buscar
              </button>
            </div>
          </div>
          <div className="button-add">
            {hasPermission('MODULE_LIST') && (
              <button
                className="basic-custom-button"
                onClick={() => handleSync()}
              >
                <FontAwesomeIcon className="basic-shortcut-icon" icon={faRotate} />
                Sync Módulos
              </button>
            )}
            <div className="dropdown-container" ref={dropdownRef}>
              <button
                className="basic-custom-button"
                onClick={toggleDropdown}
              >
                <FontAwesomeIcon className="basic-shortcut-icon" icon={faPlus} />
                Agregar
              </button>
              {showDropdown && (
                <div className="dropdown-menu">
                  {role === 'CHANEL_ADMIN' && (
                    <>
                      <button
                        className="dropdown-item"
                        onClick={() => handleOptionClick(openModal)}
                      >
                        Módulos Extras
                      </button>
                      <button
                        className="dropdown-item"
                        onClick={() => handleOptionClick(openSubscriptionModal)}
                      >
                        Suscripciones
                      </button>
                      <button
                        className="dropdown-item"
                        onClick={() => handleOptionClick(openComboModal)}
                      >
                        Combos
                      </button>
                    </>
                  )}
                  {role !== 'CHANEL_ADMIN' && hasPermission('MODULE_CREATE') && (
                    <button
                      className="dropdown-item"
                      onClick={() => {
                        handleOptionClick(() =>
                          navigate('/ModuleContract', { state: { modules: modules } })
                        );
                      }}
                    >
                      Agregar Módulo
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        </Section>
        <Table
          title={`Lista de módulos del contrato (${dataInfo.numCont}) del Cliente (${dataInfo?.razonSocial}) con identificación (${dataInfo?.cif})`}
          rows={data}
          columns={columns}
          icon={faThLarge}
          renderRow={renderRow}
          currentPage={currentPage}
          totalItems={totalItems}
          itemsPerPage={itemsPerPage}
          onPageChange={(page) => setCurrentPage(page)}
          onRowClick={handleRowClick}
          selectedRow={selectedRow}
          onRefresh={onRefreshThis}
          loading={loading}
          flagPagination={true}
        //   onRefresh={handleRefresh} // Pasar la función de actualización al componente Table
        />
        {selectedItem && (
          <ModalStatus
            message={
              selectedItem.activo
                ? "Módulo Activo. ¿Desea Inactivarlo?"
                : "Módulo Inactivo. ¿Desea Activarlo?"
            }
            isVisible={modalVisible}
            onClose={handleCloseModal}
            modulo={selectedItem.id}
            activo={selectedItem.activo}
          />
        )}
        {selectedDeleteItem && (
          <DeleteModal
            isVisible={deleteItem}
            onClose={handleCloseDeleteModal}
            identificator={selectedDeleteItem.id}
            url={`/modules`}
          />
        )}
      </div>
      <SlideModal isOpen={isModalOpen} onClose={() => toggleModal(false)} title="Detalles del Módulo">
        <div className="section-container-form">
          <h4 className="section-title-form">CANAL</h4>
          <hr className="section-divider-form" />
          <div className="form-group-form">
            <label>{selectedItem?.canal}</label>
          </div>
          <h4 className="section-title-form">CADUCA</h4>
          <hr className="section-divider-form" />
          <div className="form-group-form">
            <label>{selectedItem?.fechaFin}</label>
          </div>

          <h4 className="section-title-form">MÓDULO</h4>
          <hr className="section-divider-form" />
          <div className="form-group-form">
            <label>{selectedItem?.modulo}</label>
          </div>

          <h4 className="section-title-form">MOTIVO</h4>
          <hr className="section-divider-form" />
          <div className="form-group-form">
            <label>{selectedItem?.motivo}</label>
          </div>
          <h4 className="section-title-form">CONTRATO</h4>
          <hr className="section-divider-form" />
          <div className="form-group-form">
            <label>{selectedItem?.numContId}</label>
          </div>
          <h4 className="section-title-form">N° Licencias</h4>
          <hr className="section-divider-form" />
          <div className="form-group-form">
            <label>{selectedItem?.numLicencias}</label>
          </div>
          <h4 className="section-title-form">Origen</h4>
          <hr className="section-divider-form" />
          <div className="form-group-form">
            <label>{selectedItem?.origen}</label>
          </div>
          <h4 className="section-title-form">Usuario</h4>
          <hr className="section-divider-form" />
          <div className="form-group-form">
            <label>{selectedItem?.user}</label>
          </div>
        </div>
      </SlideModal>
      <SuccessNotification
        message={"Se ha cargado correctamente"}
        isVisible={isSuccessVisible}
        onClose={() => setIsSuccessVisible(false)}
      />
      <ErrorNotification
        message="Ups! Ocurrio un Problema"
        isVisible={isErrorVisible}
        onClose={() => setIsErrorVisible(false)}
      />
      <WarningNotification
        message="No tienes permiso para realizar esta acción."
        isVisible={isWarningVisible}
        onClose={() => setIsWarningVisible(false)}
      />
      <LoginNotification
        message="Vuele a iniciar sesión"
        isVisible={isInfoVisible}
        onClose={() => setIsInfoVisible(false)}
      />
      {isModalModulesOpen && (
        <AddModulesModal
          isVisible={isModalModulesOpen}
          onClose={() => closeModal()}
          contractId={dataInfo.numCont}
        />
      )}
      {isModalComboOpen && (
        <AddComboModal
          isVisible={isModalComboOpen}
          onClose={() => closeCmoboModal()}
          contractId={dataInfo.numCont}
        />
      )}
      {isModalSuscriptionOpen && (
        <AddSubscriptionsModal
          isVisible={isModalSuscriptionOpen}
          onClose={() => closeSubscriptionModal()}
          contractId={dataInfo.numCont}
        />
      )}
    </div>
  );
};

export default ModulesForm;
