import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import './Module.css';
import Header from '../../components/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SuccessNotification from '../../components/Notifications/SuccessNotification';
import ErrorNotification from '../../components/Notifications/ErrorNotification';
import LoginNotification from "../../components/Notifications/LoginNotification";
import DeleteModal from '../../components/DeleteModal';
import { faEdit, faTrashAlt, faCirclePlus, faCube, faSearch } from '@fortawesome/free-solid-svg-icons';
import Table from '../../components/Table'; // Asegúrate de que el path sea correcto
import Section from '../../components/Section';
import apiClient from "../../axios"; // Asegúrate de tener configurado tu cliente API
import { Tooltip } from "react-tooltip";
import { useCallback } from 'react';

const Module = ({ handleLogout }) => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [isSuccessVisible, setIsSuccessVisible] = useState(false);
  const [search, setSearch] = useState('');
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [isInfoVisible, setIsInfoVisible] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [selectedItem, setSelectedItem] = useState(null);
  const [deleteItem, setDeleteItem] = useState(false);
  const itemsPerPage = 10;

  const hasPermission = (permission) => {
    const permissions = JSON.parse(localStorage.getItem('permissions')) || [];
    return permissions.includes(permission);
  };

  const fetchData = useCallback(async (page = 1) => {
    try {
      setLoading(true);
      const params = new URLSearchParams();
      if (search) params.append('search', search);
      const response = await apiClient.get(`/products?${params.toString()}&page=${page}`);

      if (Array.isArray(response.data.results)) {
        setData(response.data.results); // If it's an array, leave it as it is
        setTotalItems(response.data.total);
      } else {
        setData([response.data.results]); // If it's an object, wrap it in an array
      }
      setIsSuccessVisible(true);
    } catch (error) {
      setIsErrorVisible(true);
      if (error.response && error.response.status === 401) {
        setIsInfoVisible(true);
      }
    } finally {
      setIsSuccessVisible(true);
      setLoading(false);
    }
  }, [search]);

  useEffect(() => {
    fetchData(currentPage); // Call the function to fetch data
  }, [fetchData, currentPage]); // Add 'fetchData' to the dependency array

  const handleRefresh = () => {
    fetchData(currentPage);
  };

  const handleDeleteModal = (item) => {
    setSelectedItem(item);
    setDeleteItem(true);
  }
  const handleCloseDeleteModal = () => {
    setDeleteItem(false);
    setSelectedItem(null);
    handleRefresh()
  };

  const columns = [
    { title: "descripcion", key: "descripcion" },
    { title: "origen", key: "origen" },
    { title: "codigo", key: "codigo" },
    { title: "Acciones", key: "acciones" },
  ];


  const renderRow = (item, index) => (
    <>
      <td>{item.descripcion}</td>
      <td>{item.origen}</td>
      <td>{item.codigo}</td>
      <td>
        <div className="button-container">
          <Tooltip id="edit-tooltip" className="custom-tooltip" />
          {hasPermission('PRODUCT_UPDATE') && (
            <button
              data-tooltip-id="edit-tooltip"
              className="icon-button edit-button"
              data-tooltip-content="Editar"
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/ModuleNew/${item.id}`);
              }}
            >
              <FontAwesomeIcon icon={faEdit} />
            </button>
          )}
          <Tooltip id="delete-tooltip" className="custom-tooltip" />
          {hasPermission('PRODUCT_DELETE') && (
            <button
              data-tooltip-id="delete-tooltip"
              data-tooltip-content="Eliminar"
              className="icon-button delete-button"
              onClick={() => handleDeleteModal(item)}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </button>
          )}
        </div>
      </td>
    </>
  );

  return (
    <div className="home-container">
      <Header onLogout={handleLogout} title='Módulos' />

      <div className="home-content">
        <Section>
          <div className="filter-form">
            <div className="basic-info-form-group">
              <input
                type="text"
                id="customer"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Descripción"
              />
            </div>
            <button className="search-button-customer" onClick={handleRefresh}  >
              <FontAwesomeIcon icon={faSearch} className="search-icon" />
              Buscar
            </button>
          </div>
          <div className="button-add">
            {hasPermission('PRODUCT_CREATE') && (
              <button
                className="basic-custom-button"
                onClick={(e) => {
                  e.stopPropagation();
                  navigate('/ModuleNew');
                }}
              >
                <FontAwesomeIcon className="basic-shortcut-icon" icon={faCirclePlus} />
                Crear Nuevo Módulo
              </button>
            )}
          </div>
        </Section>
        <Table
          title='Lista de Módulos'
          rows={data}
          columns={columns}
          icon={faCube}
          renderRow={renderRow}
          currentPage={currentPage}
          totalItems={totalItems}
          itemsPerPage={itemsPerPage}
          onRefresh={handleRefresh}
          onPageChange={(page) => setCurrentPage(page)}
          loading={loading}
          flagPagination={true}
        />
        <SuccessNotification
          message={"Se ha cargado correctamente"}
          isVisible={isSuccessVisible}
          onClose={() => setIsSuccessVisible(false)}
        />
        <ErrorNotification
          message="Oops! Ocurrió un Problema"
          isVisible={isErrorVisible}
          onClose={() => setIsErrorVisible(false)}
        />
        <LoginNotification
          message="Vuelve a iniciar sesión"
          isVisible={isInfoVisible}
          onClose={() => setIsInfoVisible(false)}
          onLogout={handleLogout}
        />
        {selectedItem && (
          <DeleteModal
            isVisible={deleteItem}
            onClose={handleCloseDeleteModal}
            identificator={selectedItem.id}
            url={`/products`}
          />
        )}
      </div>
    </div>
  );
};

export default Module;
